import React, { ReactNode, useEffect, useRef, useState } from "react";
import isEqual from "lodash.isequal";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TokenGroup from "@cloudscape-design/components/token-group";
import { BaseInputProps } from "..";
import { useFocusButton } from "../../../hooks";
import GenAiOutputLabel from "./GenAiOutputLabel";
import GenAiOutputUserActionControl from "./GenAiOutputActionControl";
import { GenAiOutputElementIdentifier } from "./genAiTypes";

interface UseGenAiOutputProps<T> extends GenAiOutputElementIdentifier {
  validate?: BaseInputProps<T>["validate"];
  genAiOutputValue?: T;
  currentInput: T;
  setInput: (value: T) => void;
  resetInput: () => void;
  /**
   * Only string type is supported.
   */
  label?: ReactNode;
}

enum GenAiOutputState {
  NO_OUTPUT,
  OUTPUT_AUTO_POPULATED,
  OUTPUT_PENDING_USER_ACTION,
}

export function useGenAiOutput<T extends string | string[]>({
  validate,
  genAiOutputValue,
  currentInput,
  setInput,
  resetInput,
  identifier,
  label,
}: UseGenAiOutputProps<T>) {
  const [genAiOutputElement, setGenAiOutputElement] = useState<ReactNode>();
  const [isGenAiOutputAccepted, setIsGenAiOutputAccepted] =
    useState<boolean>(false);

  const { buttonRef: triggerButtonRef, focusButton: focusTriggerButton } =
    useFocusButton();
  const { buttonRef: resetButtonRef, focusButton: focusResetButton } =
    useFocusButton();
  const shouldFocusTriggerButton = useRef(false);
  const shouldFocusResetButton = useRef(false);

  const genAiOutputState = useRef<GenAiOutputState>(GenAiOutputState.NO_OUTPUT);

  const genAiOutputLabel = <GenAiOutputLabel>Suggested by AI</GenAiOutputLabel>;

  const genAiOutput = Array.isArray(genAiOutputValue) ? (
    <TokenGroup
      readOnly={true}
      items={genAiOutputValue.map((value) => ({ label: value }))}
    />
  ) : (
    genAiOutputValue
  );

  const userActionControl = (
    <SpaceBetween direction="horizontal" size="s">
      <GenAiOutputUserActionControl
        ref={triggerButtonRef}
        header={
          typeof label === "string"
            ? `Suggested ${label.toLowerCase()}`
            : undefined
        }
        buttonText="View suggested metadata"
        genAiOutput={genAiOutput}
        identifier={identifier}
        disabled={isGenAiOutputAccepted}
        onAcceptClick={acceptGenAiOutputValue}
      />
      {isGenAiOutputAccepted && (
        <Button
          ref={resetButtonRef}
          data-testid={`gen-ai-output-reset-button-${identifier}`}
          variant="inline-link"
          iconName="undo"
          onClick={resetGenAiPopulatedValue}
        >
          Reset
        </Button>
      )}
    </SpaceBetween>
  );

  useEffect(() => {
    if (genAiOutputValue && (validate?.(genAiOutputValue).isValid ?? true)) {
      // GenAI output value is available and valid
      switch (genAiOutputState.current) {
      case GenAiOutputState.NO_OUTPUT:
        // GenAI output has not been processed yet
        if (!currentInput || currentInput.length === 0) {
          // Current input is empty
          genAiOutputState.current = GenAiOutputState.OUTPUT_AUTO_POPULATED;
          setInput(genAiOutputValue);
          setGenAiOutputElement(genAiOutputLabel);
        } else {
          // Current input has existing value
          genAiOutputState.current =
              GenAiOutputState.OUTPUT_PENDING_USER_ACTION;
          setGenAiOutputElement(userActionControl);
        }
        break;
      case GenAiOutputState.OUTPUT_AUTO_POPULATED:
        // GenAI output has previously been auto-populated
        if (!isEqual(currentInput, genAiOutputValue)) {
          // GenAI output value has been modified by user
          setGenAiOutputElement(undefined);
        } else {
          // GenAI output value has not been modified by user
          setGenAiOutputElement(genAiOutputLabel);
        }
        break;
      case GenAiOutputState.OUTPUT_PENDING_USER_ACTION:
        setGenAiOutputElement(userActionControl);
        break;
      }
    }
  }, [genAiOutputValue, currentInput, isGenAiOutputAccepted]);

  useEffect(() => {
    if (shouldFocusResetButton.current) {
      focusResetButton();
      shouldFocusResetButton.current = false;
    } else if (shouldFocusTriggerButton.current) {
      focusTriggerButton();
      shouldFocusTriggerButton.current = false;
    }
  }, [genAiOutputElement]);

  function resetGenAiPopulatedValue() {
    resetInput();
    shouldFocusTriggerButton.current = true;
    setIsGenAiOutputAccepted(false);
  }

  function acceptGenAiOutputValue() {
    genAiOutputValue && setInput(genAiOutputValue);
    shouldFocusResetButton.current = true;
    setIsGenAiOutputAccepted(true);
  }

  return {
    genAiOutputElement,
  };
}
