export type {
  UDSDocument as Document,
  CreateDocumentCommandInput,
  GetDocumentMetadataOptionsOutput,
  GenerateDocumentMetadataResponse as GeneratedDocumentMetadata,
} from "@amzn/xcm-insights-uds-core-service-client";
import type {
  GetDocumentMetadataOptionsOutput,
  CreateDocumentInput as UDSCreateDocumentInput,
} from "@amzn/xcm-insights-uds-core-service-client";

export type CreateDocumentInput = Omit<
  UDSCreateDocumentInput,
  "lowerDocumentTitle" | "lowerFileName"
>;

export enum DocumentStatus {
  ACTIVE = "Active",
  EXPIRED = "Expired",
}

export type DocumentMetadataOptions = Partial<
  Omit<GetDocumentMetadataOptionsOutput, "metaDataVersion">
>;

export type ValueLabel = { value: string; label?: string };

export type DocumentMetadataOptionsForInputs = {
  [k in keyof DocumentMetadataOptions]?: ValueLabel[];
} & {
  projects?: ValueLabel[];
  documentYearQuarterMonth?: ValueLabel[];
  authors?: ValueLabel[];
  documentTitles?: ValueLabel[];
};
