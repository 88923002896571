import React from "react";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import styles from "./GenAiOutputActionControlButton.module.scss";
import { GenAiOutputElementIdentifier } from "./genAiTypes";

export interface GenAiOutputActionControlButtonProps
  extends GenAiOutputElementIdentifier {
  onAcceptClick?: () => void;
  onDeclineClick?: () => void;
}

function GenAiOutputActionButton({
  onAcceptClick,
  onDeclineClick,
  identifier,
}: GenAiOutputActionControlButtonProps) {
  return (
    <div className={styles.buttonGroup}>
      <SpaceBetween direction="horizontal" size="s">
        <Button
          data-testid={`gen-ai-output-action-accept-button-${identifier}`}
          data-selector="gen-ai-button"
          variant="inline-link"
          iconName="check"
          onClick={onAcceptClick}
        >
          Accept
        </Button>
        <div className={styles.divider} />
        <Button
          data-testid={`gen-ai-output-action-decline-button-${identifier}`}
          data-selector="gen-ai-button"
          variant="inline-link"
          iconName="close"
          onClick={onDeclineClick}
        >
          Decline
        </Button>
      </SpaceBetween>
    </div>
  );
}

export default GenAiOutputActionButton;
