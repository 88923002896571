import React, { FC, ReactNode } from "react";
import Header, { HeaderProps } from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button, { ButtonProps } from "@cloudscape-design/components/button";
import { useNavigate } from "react-router-dom";

export interface TableHeaderProps
  extends Pick<HeaderProps, "counter" | "description" | "children" | "info"> {
  showBackButton?: boolean;
  showRefreshButton?: boolean;
  refreshButtonOnClick?: ButtonProps["onClick"];
  buttons?: ReactNode[];
}

const TableHeader: FC<TableHeaderProps> = ({
  counter,
  description,
  children,
  showBackButton,
  showRefreshButton,
  refreshButtonOnClick,
  buttons,
  info,
}) => {
  const navigate = useNavigate();
  return (
    <Header
      data-testid="table-header"
      info={info}
      counter={counter}
      description={description}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {showRefreshButton ? (
            <Button
              ariaLabel="refresh"
              iconName="refresh"
              onClick={refreshButtonOnClick}
              data-testid="table-header-refresh"
            />
          ) : undefined}
          {showBackButton ? (
            <Button
              ariaLabel="back"
              iconName="angle-left"
              onClick={() => navigate(-1)}
              disabled={window.history.length === 1}
              data-testid="table-header-back"
            >
              Back
            </Button>
          ) : undefined}
          {buttons}
        </SpaceBetween>
      }
    >
      {children}
    </Header>
  );
};

export default TableHeader;
