
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_189so_149",
  "drawer-closed": "awsui_drawer-closed_1r9lg_189so_189",
  "refresh": "awsui_refresh_1r9lg_189so_189",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_189so_194",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_189so_210",
  "position-bottom": "awsui_position-bottom_1r9lg_189so_217",
  "with-toolbar": "awsui_with-toolbar_1r9lg_189so_234",
  "position-side": "awsui_position-side_1r9lg_189so_249",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_189so_258",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_189so_269",
  "open-button-side": "awsui_open-button-side_1r9lg_189so_283",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_189so_292",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_189so_302",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_189so_306",
  "content-bottom": "awsui_content-bottom_1r9lg_189so_318",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_189so_337",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_189so_342",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_189so_346",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_189so_350",
  "content-side": "awsui_content-side_1r9lg_189so_355",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_189so_367",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_189so_376",
  "header": "awsui_header_1r9lg_189so_387",
  "header-text": "awsui_header-text_1r9lg_189so_400",
  "header-actions": "awsui_header-actions_1r9lg_189so_413",
  "divider": "awsui_divider_1r9lg_189so_421"
};
  