import HelpPanel from "@cloudscape-design/components/help-panel";
import React from "react";

export function ProjectDashboardTableHelpPanel() {
  return (
    <HelpPanel
      data-testid="project-dashboard-table-help-panel"
      header={<h2>Projects table</h2>}
    >
      <div>
        <p>Displays projects accessible to you.</p>
        <h4>Search option</h4>
        <p>Filter or search for projects in one of two ways:</p>
        <ul>
          <li>
            <b>Semantic search (disabled)</b> - Filter projects by project or
            document metadata. Select one or more properties from the search bar
            and provide the desired property value.
          </li>
          <li>
            <b>Semantic search (enabled)</b> -{" "}
            <i>This is currently a beta feature.</i> Search for projects using
            natural language by entering search phrases related to project or
            document metadata. E.g.{" "}
            <i>&quot;All newsletters from 2023 to 2024&quot;</i>.
          </li>
        </ul>
      </div>
    </HelpPanel>
  );
}
