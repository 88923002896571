import {
  DocumentMetadataOptionsForInputs,
  DocumentStatus,
  GeneratedDocumentMetadata,
} from "../../../client/interfaces";
import {
  dateComparisonValidator,
  externalValidator,
  logicalOrValidator,
  minLengthValidator,
  regexValidator,
  requiredValidator,
  Validator,
} from "../../../helpers";
import {
  TextAreaInputProps,
  TypedTextAreaInput,
} from "../../../components/inputs/TextAreaInput";
import {
  SingleSelectInputProps,
  TypedSingleSelectInput,
} from "../../../components/inputs/SingleSelectInput";
import { BaseDropdownHostProps } from "../../../components/inputs";
import {
  TextInputProps,
  TypedTextInput,
} from "../../../components/inputs/TextInput";
import {
  MultiSelectInputProps,
  TypedMultiSelectInput,
} from "../../../components/inputs/MultiSelectInput";
import {
  DatePickerInputProps,
  TypedDatePickerInput,
} from "../../../components/inputs/DatePickerInput";
import {
  FileUploadInputProps,
  TypedFileUploadInput,
} from "../../../components/inputs/FileUploadInput";
import {
  ARRAY_SEPARATOR,
  MINIMUM_DOCUMENT_TITLE_LENGTH,
} from "../../../data/constants/common";
import { ProjectMetadataName } from "../../common/projectConstant";
import {
  AutosuggestInputProps,
  TypedAutosuggestInput,
} from "../../../components/inputs/AutosuggestInput";
import { DocumentMetadataName } from "../../common/documentConstant";
import { FormInputsComponentWithProps } from "../../common/form/inputComponentTypes";
import {
  MultiTextInputProps,
  TypedMultiTextInput,
} from "../../../components/inputs/MultiTextInput";
import { DocumentFormFieldId, DocumentInputValue } from "./documentFormTypes";

const DocumentTextAreaInput = TypedTextAreaInput<DocumentFormFieldId>();
const DocumentTextInput = TypedTextInput<DocumentFormFieldId>();
const DocumentMultiTextInput = TypedMultiTextInput<DocumentFormFieldId>();
const DocumentSingleSelectInput = TypedSingleSelectInput<DocumentFormFieldId>();
const DocumentMultiSelectInput = TypedMultiSelectInput<DocumentFormFieldId>();
const DocumentDatePickerInput = TypedDatePickerInput<DocumentFormFieldId>();
const DocumentFileUploadInput = TypedFileUploadInput<DocumentFormFieldId>();
const DocumentAutosuggestInput = TypedAutosuggestInput<DocumentFormFieldId>();

export interface DocumentFormInputsProps {
  options?: DocumentMetadataOptionsForInputs;
  defaultValue: DocumentInputValue;
  readOnly?: {
    [key in Extract<DocumentFormFieldId, "projectDescription">]?: boolean;
  };
  disabled?: {
    [key in Extract<
      DocumentFormFieldId,
      "status" | "documentExpirationDate"
    >]?: boolean;
  };
  externalValidate?: {
    [key in Extract<
      DocumentFormFieldId,
      "projectId" | "documentTitle" | "documentExpirationDate"
    >]?: () => boolean;
  };
  manualFilterOptions?: {
    [key in Extract<
      DocumentFormFieldId,
      "documentTitle" | "documentYearQuarterMonth"
    >]?: BaseDropdownHostProps;
  };
  generatedDocumentMetadata?: GeneratedDocumentMetadata;
}

type DocumentFormInputsKey = keyof ReturnType<typeof DOCUMENT_FORM_INPUTS>;
export const CREATE_NEW_PROJECT_INPUT = "Create new project";

/**
 * This object defines the type of each input field, the component
 * used, labels, description, validation rules, etc.
 */
export const DOCUMENT_FORM_INPUTS = ({
  options,
  defaultValue,
  readOnly,
  disabled,
  externalValidate,
  manualFilterOptions,
  generatedDocumentMetadata,
}: DocumentFormInputsProps): {
  [k in
    | ProjectMetadataName.PROJECT_DESCRIPTION
    | DocumentMetadataName.DOCUMENT_SUMMARY
    | DocumentMetadataName.ADDITIONAL_NOTES
    | DocumentMetadataName.LAST_UPDATE_COMMENT]: FormInputsComponentWithProps<
    TextAreaInputProps<DocumentFormFieldId>
  >;
} & {
  [k in DocumentMetadataName.AUTHOR]: FormInputsComponentWithProps<
    TextInputProps<DocumentFormFieldId>
  >;
} & {
  [k in DocumentMetadataName.DOCUMENT_TAGS]: FormInputsComponentWithProps<
    MultiTextInputProps<DocumentFormFieldId>
  >;
} & {
  [k in
    | DocumentMetadataName.DOCUMENT_TITLE
    | typeof CREATE_NEW_PROJECT_INPUT]: FormInputsComponentWithProps<
    AutosuggestInputProps<DocumentFormFieldId>
  >;
} & {
  [k in
    | ProjectMetadataName.PROJECT_TITLE
    | DocumentMetadataName.PRIMARY_SUBJECT_AREA
    | DocumentMetadataName.STATUS
    | DocumentMetadataName.DOCUMENT_CLASSIFICATION]: FormInputsComponentWithProps<
    SingleSelectInputProps<DocumentFormFieldId>
  >;
} & {
  [k in
    | DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH
    | DocumentMetadataName.MARKETPLACES
    | DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS
    | DocumentMetadataName.BIG_ROCKS
    | DocumentMetadataName.DOCUMENT_CONTENT_TYPES]: FormInputsComponentWithProps<
    MultiSelectInputProps<DocumentFormFieldId>
  >;
} & {
  [k in DocumentMetadataName.DOCUMENT_EXPIRATION_DATE]: FormInputsComponentWithProps<
    DatePickerInputProps<DocumentFormFieldId>
  >;
} & {
  [k in DocumentMetadataName.FILENAME]: FormInputsComponentWithProps<
    FileUploadInputProps<DocumentFormFieldId>
  >;
} => ({
  [ProjectMetadataName.PROJECT_DESCRIPTION]: {
    component: DocumentTextAreaInput,
    props: {
      label: ProjectMetadataName.PROJECT_DESCRIPTION,
      placeholder: "A brief summary of the project contents",
      defaultValue: defaultValue.projectDescription,
      fieldId: "projectDescription",
      required: true,
      readOnly: readOnly?.projectDescription,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
    },
  },
  [DocumentMetadataName.DOCUMENT_SUMMARY]: {
    component: DocumentTextAreaInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_SUMMARY,
      placeholder: "A brief summary of the document contents",
      defaultValue: defaultValue.documentSummary,
      genAiOutputValue: generatedDocumentMetadata?.documentSummary,
      fieldId: "documentSummary",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
    },
  },
  [DocumentMetadataName.ADDITIONAL_NOTES]: {
    component: DocumentTextAreaInput,
    props: {
      label: DocumentMetadataName.ADDITIONAL_NOTES,
      placeholder: "Additional notes about the document",
      defaultValue: defaultValue.additionalNote,
      fieldId: "additionalNote",
      required: false,
      validate: Validator.builder<string>()
        .addValidator(minLengthValidator(0))
        .build(),
    },
  },
  [DocumentMetadataName.LAST_UPDATE_COMMENT]: {
    component: DocumentTextAreaInput,
    props: {
      label: "Change log",
      placeholder: "Change log on the document",
      defaultValue: defaultValue.lastUpdateComment,
      fieldId: "lastUpdateComment",
      required: false,
      validate: Validator.builder<string>()
        .addValidator(minLengthValidator(0))
        .build(),
    },
  },
  [DocumentMetadataName.AUTHOR]: {
    component: DocumentTextInput,
    props: {
      label: DocumentMetadataName.AUTHOR,
      placeholder: "Author alias",
      defaultValue: defaultValue.author,
      fieldId: "author",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .addValidator(
          regexValidator(
            new RegExp("^[a-z]+$"),
            "Alias must contain only lower case alphabets",
          ),
        )
        .build(),
    },
  },
  [DocumentMetadataName.DOCUMENT_TAGS]: {
    component: DocumentMultiTextInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_TAGS,
      placeholder: "Document tags",
      constraintText: "After typing, press ENTER to add a tag",
      defaultValue: defaultValue.documentTags,
      fieldId: "documentTags",
      required: false,
      validate: Validator.builder<string[]>()
        .addValidator(minLengthValidator(0))
        .build(),
    },
  },
  [DocumentMetadataName.DOCUMENT_TITLE]: {
    component: DocumentAutosuggestInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_TITLE,
      placeholder: "Document title",
      defaultValue: defaultValue.documentTitle,
      genAiOutputValue: generatedDocumentMetadata?.documentTitle,
      fieldId: "documentTitle",
      required: true,
      options: options?.documentTitles,
      filteringType: "manual",
      manualFilterOptions: manualFilterOptions?.documentTitle,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .addValidator(
          minLengthValidator(
            MINIMUM_DOCUMENT_TITLE_LENGTH,
            `Document title must be at least ${MINIMUM_DOCUMENT_TITLE_LENGTH} characters long`,
          ),
        )
        .addValidator(
          externalValidator(
            externalValidate?.documentTitle,
            "Document title already exists",
          ),
        )
        .build(),
    },
  },
  [CREATE_NEW_PROJECT_INPUT]: {
    component: DocumentAutosuggestInput,
    props: {
      placeholder: "Enter title of the new project",
      defaultValue: "",
      fieldId: "projectId",
      required: true,
      options: options?.projects?.map((project) => ({
        value: project.label ?? project.value,
      })),
      filteringType: "auto",
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .addValidator(
          externalValidator(
            externalValidate?.projectId,
            "Project title already exists",
          ),
        )
        .build(),
    },
  },
  [ProjectMetadataName.PROJECT_TITLE]: {
    component: DocumentSingleSelectInput,
    props: {
      label: ProjectMetadataName.PROJECT_TITLE,
      description: "Project associated with the document",
      placeholder: "Select project",
      constraintText:
        "Select project or create a new one by clicking the button below",
      defaultValue: defaultValue.projectId,
      fieldId: "projectId",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
      options: options?.projects,
    },
  },
  [DocumentMetadataName.PRIMARY_SUBJECT_AREA]: {
    component: DocumentSingleSelectInput,
    props: {
      label: DocumentMetadataName.PRIMARY_SUBJECT_AREA,
      placeholder: "Select subject area",
      defaultValue: defaultValue.primarySubjectArea,
      genAiOutputValue: generatedDocumentMetadata?.primarySubjectArea,
      fieldId: "primarySubjectArea",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
      options: options?.primarySubjectAreas,
    },
  },
  [DocumentMetadataName.STATUS]: {
    component: DocumentSingleSelectInput,
    props: {
      label: DocumentMetadataName.STATUS,
      placeholder: "Active",
      defaultValue: defaultValue.status,
      fieldId: "status",
      required: true,
      disabled: disabled?.status ?? true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
      options: Object.values(DocumentStatus).map((status) => ({
        label: status,
        value: status,
      })),
    },
  },
  [DocumentMetadataName.DOCUMENT_CLASSIFICATION]: {
    component: DocumentSingleSelectInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_CLASSIFICATION,
      placeholder: "Confidential",
      defaultValue: defaultValue.documentClassification,
      fieldId: "documentClassification",
      required: true,
      validate: Validator.builder<string>()
        .addValidator(requiredValidator())
        .build(),
      options: options?.classifications,
    },
  },
  [DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH]: {
    component: DocumentMultiSelectInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
      placeholder: "Select year/quarter/month",
      defaultValue: defaultValue.documentYearQuarterMonth,
      genAiOutputValue: generatedDocumentMetadata?.documentYearQuarterMonth,
      fieldId: "documentYearQuarterMonth",
      required: true,
      validate: Validator.builder<string[]>()
        .addValidator(requiredValidator())
        .addValidator(
          regexValidator(
            new RegExp("^[1-9][0-9]{3}(?:-Q[1-4]|-0[1-9]|-1[0-2]){0,1}$"),
            "Value must be in the format of YYYY, YYYY-QQ, or YYYY-MM",
          ),
        )
        .build(),
      options: options?.documentYearQuarterMonth,
      filteringType: "manual",
      manualFilterOptions: manualFilterOptions?.documentYearQuarterMonth,
    },
  },
  [DocumentMetadataName.MARKETPLACES]: {
    component: DocumentMultiSelectInput,
    props: {
      label: DocumentMetadataName.MARKETPLACES,
      description: "Marketplaces associated with the document",
      placeholder: "Select marketplaces",
      defaultValue: defaultValue.marketplaces,
      genAiOutputValue: generatedDocumentMetadata?.marketplaces,
      fieldId: "marketplaces",
      required: true,
      validate: Validator.builder<string[]>()
        .addValidator(requiredValidator())
        .build(),
      options: options?.marketplaces,
      selectAllOption: true,
    },
  },
  [DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS]: {
    component: DocumentMultiSelectInput,
    props: {
      label: DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
      placeholder: "Select subject area",
      defaultValue: defaultValue.additionalSubjectAreas,
      genAiOutputValue: generatedDocumentMetadata?.additionalSubjectAreas,
      fieldId: "additionalSubjectAreas",
      required: false,
      validate: Validator.builder<string[]>()
        .addValidator(minLengthValidator(0))
        .build(),
      options: options?.additionalSubjectAreas,
    },
  },
  [DocumentMetadataName.BIG_ROCKS]: {
    component: DocumentMultiSelectInput,
    props: {
      label: DocumentMetadataName.BIG_ROCKS,
      placeholder: "Select big rocks",
      defaultValue: defaultValue.bigRocks,
      genAiOutputValue: generatedDocumentMetadata?.bigRocks,
      fieldId: "bigRocks",
      required: false,
      validate: Validator.builder<string[]>()
        .addValidator(minLengthValidator(0))
        .build(),
      options: options?.bigRocks,
    },
  },
  [DocumentMetadataName.DOCUMENT_CONTENT_TYPES]: {
    component: DocumentMultiSelectInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
      placeholder: "Select content types",
      defaultValue: defaultValue.documentContentTypes,
      genAiOutputValue: generatedDocumentMetadata?.documentContentTypes,
      fieldId: "documentContentTypes",
      required: false,
      validate: Validator.builder<string[]>()
        .addValidator(minLengthValidator(0))
        .build(),
      options: options?.documentContentTypes,
    },
  },
  [DocumentMetadataName.DOCUMENT_EXPIRATION_DATE]: {
    component: DocumentDatePickerInput,
    props: {
      label: DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
      placeholder: "YYYY/MM/DD",
      defaultValue: defaultValue.documentExpirationDate,
      fieldId: "documentExpirationDate",
      required: false,
      disabled: disabled?.documentExpirationDate,
      isDateEnabled: (date) => date.valueOf() > Date.now(),
      validate: Validator.builder<string>()
        .addValidator(
          logicalOrValidator(
            [
              dateComparisonValidator(
                (timestamp) => timestamp === 0 || timestamp > Date.now(),
              ),
              externalValidator(
                externalValidate?.documentExpirationDate ?? (() => false),
              ),
            ],
            "Expiration date must be in the future",
          ),
        )
        .build(),
    },
  },
  [DocumentMetadataName.FILENAME]: {
    component: DocumentFileUploadInput,
    props: {
      label: "Document upload",
      defaultValue: defaultValue.fileName,
      defaultFileSizeInBytes: defaultValue.fileSizeInBytes,
      fieldId: "fileName",
      constraintText: `Supported formats: ${(options?.supportedDocumentTypes?.map((value) => value.value) ?? []).join(ARRAY_SEPARATOR).toUpperCase()}`,
      required: true,
      validate: Validator.builder<File[]>()
        .addValidator(requiredValidator())
        .build(),
    },
  },
});

export const DOCUMENT_FORM_PROJECT_INFORMATION = (
  props: DocumentFormInputsProps,
) => {
  const keys: DocumentFormInputsKey[] = [
    ProjectMetadataName.PROJECT_TITLE,
    ProjectMetadataName.PROJECT_DESCRIPTION,
  ];
  return keys.map((metadataName) => DOCUMENT_FORM_INPUTS(props)[metadataName]);
};

export const DOCUMENT_FORM_GENERAL_INFORMATION = (
  props: DocumentFormInputsProps,
) => {
  const keys: DocumentFormInputsKey[] = [
    DocumentMetadataName.FILENAME,
    DocumentMetadataName.DOCUMENT_TITLE,
    DocumentMetadataName.DOCUMENT_SUMMARY,
    DocumentMetadataName.MARKETPLACES,
    DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    DocumentMetadataName.AUTHOR,
    DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    DocumentMetadataName.STATUS,
    DocumentMetadataName.DOCUMENT_CLASSIFICATION,
  ];
  return keys.map((metadataName) => DOCUMENT_FORM_INPUTS(props)[metadataName]);
};

export const DOCUMENT_FORM_ADDITIONAL_INFORMATION = (
  props: DocumentFormInputsProps,
) => {
  const keys: DocumentFormInputsKey[] = [
    DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    DocumentMetadataName.BIG_ROCKS,
    DocumentMetadataName.DOCUMENT_TAGS,
    DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    DocumentMetadataName.ADDITIONAL_NOTES,
    DocumentMetadataName.LAST_UPDATE_COMMENT,
  ];
  return keys.map((metadataName) => DOCUMENT_FORM_INPUTS(props)[metadataName]);
};
