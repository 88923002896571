import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";

import SideNavigation from "@cloudscape-design/components/side-navigation";
import AppLayout, {
  AppLayoutProps,
} from "@cloudscape-design/components/app-layout";
import { useLocation, useNavigate } from "react-router-dom";
import FlashBarMessages from "../common/FlashbarMessages";
import { NAV_ITEMS } from "../../data/constants/navigation";

import ErrorBoundary from "../errorboundary/ErrorBoundary";
import {
  useDocumentMetadataOptions,
  useLocalStoragePreferences,
} from "../../hooks";
import { APP_NAME, RoutePath } from "../../data/constants/common";
import { MessageContext } from "../../context/MessagingContext";
import BreadCrumbs from "./BreadCrumbs";

const APP_LAYOUT_PREFERENCES_KEY = "appLayoutPreferences";
interface AppLayoutPreferences {
  navigationOpen?: boolean;
}

interface CustomeAppLayoutProps
  extends Omit<
    AppLayoutProps,
    | "stickyNotifications"
    | "toolsHide"
    | "headerSelector"
    | "ariaLabels"
    | "maxContentWidth"
    | "navigation"
    | "breadcrumbs"
    | "notications"
  > {
  projectTitle?: string;
}

const CustomAppLayout: FC<CustomeAppLayoutProps> = ({
  content,
  projectTitle,
  toolsOpen,
  ...props
}): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeHref, setActiveHref] = useState(
    window.location.pathname === "/"
      ? RoutePath.ALL_PROJECTS
      : window.location.pathname,
  );
  const { preferences, setPreferences } =
    useLocalStoragePreferences<AppLayoutPreferences>(
      APP_LAYOUT_PREFERENCES_KEY,
    );
  const { data: documentMetadataOptions } = useDocumentMetadataOptions();
  const { messages } = useContext(MessageContext);

  useEffect(() => {
    // Replace "+" with " " to make the href matching side navigation
    setActiveHref(
      decodeURIComponent(location.pathname + location.search).replace("+", " "),
    );
  }, [location]);

  return (
    <AppLayout
      data-testid="custom-app-layout"
      stickyNotifications
      toolsHide={!toolsOpen}
      toolsOpen={toolsOpen}
      headerSelector="#h"
      ariaLabels={{ navigationClose: "close" }}
      maxContentWidth={Number.MAX_VALUE}
      navigationOpen={preferences?.navigationOpen ?? true}
      onNavigationChange={({ detail: { open } }) =>
        setPreferences({ ...preferences, navigationOpen: open })
      }
      navigation={
        <div>
          <SideNavigation
            header={{ href: "/#", text: APP_NAME }}
            activeHref={activeHref}
            items={NAV_ITEMS({
              subjectAreaOptions:
                documentMetadataOptions?.primarySubjectAreas ?? [],
            })}
            onFollow={(event) => {
              if (!event.detail.external) {
                event.preventDefault();
                const href = event.detail.href;
                navigate(href);
              }
            }}
          />
        </div>
      }
      breadcrumbs={
        <BreadCrumbs
          pathParamsLabels={{ projectId: projectTitle, projectTitle }}
        />
      }
      notifications={
        <FlashBarMessages
          messages={messages}
          data-testid="custom-app-layout-flashbar-messages"
        />
      }
      content={<ErrorBoundary>{content}</ErrorBoundary>}
      {...props}
    />
  );
};

export default CustomAppLayout;
