import React, { useContext, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  Project,
  Document,
  ValueLabel,
  ValidationFunction,
  DocumentStatus,
} from "../../../client/interfaces";
import {
  BaseInputRefAttributes,
  InputChangeDetail,
} from "../../../components/inputs";
import CustomModal from "../../../components/common/CustomModal";
import { FormInputsComponentWithProps } from "../../common/form/inputComponentTypes";
import { AutosuggestInputProps } from "../../../components/inputs/AutosuggestInput";
import { AuthContext } from "../../../context/AuthContext";
import { useGetDocumentTitles } from "../../../hooks";
import {
  DEFAULT_DEBOUNCE_DELAY_MILLIS,
  MINIMUM_DOCUMENT_TITLE_LENGTH,
} from "../../../data/constants/common";
import { objectMap } from "../../../helpers";
import { getInitialDocumentFormValues } from "./documentFormInitialValues";
import {
  DocumentFormBoolean,
  DocumentFormFieldId,
  DocumentFormSubmitAction,
  DocumentInputValue,
} from "./documentFormTypes";
import { CREATE_NEW_PROJECT_INPUT } from "./documentFormConfig";

interface UseDocumentFormChangeProps {
  projects?: Project[];
  project?: Project;
  document?: Document;
  submitAction: DocumentFormSubmitAction;
  validateDocumentTitle?: ValidationFunction<string>;
  validateNewProjectTitle?: ValidationFunction<string>;
}

export function useDocumentFormChange({
  project,
  document,
  submitAction,
  projects,
  validateDocumentTitle,
  validateNewProjectTitle,
}: UseDocumentFormChangeProps) {
  const { alias } = useContext(AuthContext);

  const {
    defaultDocumentInputValue,
    defaultDocumentFormIsError,
    defaultDocumentFormIsModified,
  } = getInitialDocumentFormValues({
    documentFormSubmitAction: submitAction,
    document,
    project,
    authorAlias: alias,
  });

  const [documentInputValue, setDocumentInputValue] =
    useState<DocumentInputValue>(defaultDocumentInputValue);
  const documentFormIsError = useRef<DocumentFormBoolean>(
    defaultDocumentFormIsError,
  );
  const documentFormIsModified = useRef<DocumentFormBoolean>(
    defaultDocumentFormIsModified,
  );

  // Check existing document title states
  const documentTitleExists = useRef(true);
  const [documentTitleToCheck, setDocumentTitleToCheck] = useState<
    string | undefined
  >(undefined);
  const { data: documentTitleResult, isLoading: isLoadingDocumentTitleResult } =
    useGetDocumentTitles(documentTitleToCheck);
  const debounceDocumentTitleToCheck = useDebouncedCallback(
    (value?: string) =>
      setDocumentTitleToCheck(
        (value ?? "").length >= MINIMUM_DOCUMENT_TITLE_LENGTH
          ? value
          : undefined,
      ),
    DEFAULT_DEBOUNCE_DELAY_MILLIS,
  );

  // New project title states
  const [createNewProjectTitleVisible, setCreateNewProjectTitleVisible] =
    useState(false);
  const [newProjectTitleOption, setNewProjectTitleOption] =
    useState<ValueLabel>();
  const newProjectTitle = useRef("");
  const isNewProjectTitleError = useRef(true);

  // Check existing project title states
  const projectTitleExists = useRef(true);
  const debounceProjectTitleExists = useDebouncedCallback((value: string) => {
    if (projects?.find((project) => project.projectTitle === value)) {
      projectTitleExists.current = true;
    } else {
      projectTitleExists.current = false;
    }
    const validationOutput = validateNewProjectTitle?.(value);
    isNewProjectTitleError.current = !(validationOutput?.isValid ?? true);

    if (value) {
      createNewProjectTitleRef.current?.validate();
    }
  }, DEFAULT_DEBOUNCE_DELAY_MILLIS);

  useEffect(() => {
    const projectDescriptionForSelectedProjectTitle = projects?.find(
      (project) => project.projectId === documentInputValue.projectId,
    )?.projectDescription;
    if (projectDescriptionForSelectedProjectTitle) {
      refsMap.projectDescription?.current?.overrideValue?.(
        projectDescriptionForSelectedProjectTitle,
      );
    }
  }, [documentInputValue.projectId]);

  useEffect(() => {
    if (isLoadingDocumentTitleResult) {
      return;
    }

    documentTitleExists.current =
      (documentTitleResult ?? []).includes(documentInputValue.documentTitle) &&
      Boolean(documentFormIsModified.current.documentTitle);

    const validationOutput = validateDocumentTitle?.(
      documentInputValue.documentTitle,
    );

    documentFormIsError.current = {
      ...documentFormIsError.current,
      documentTitle: !(validationOutput?.isValid ?? true),
    };

    if (
      documentInputValue.documentTitle &&
      documentInputValue.documentTitle.length >= MINIMUM_DOCUMENT_TITLE_LENGTH
    ) {
      refsMap.documentTitle?.current?.validate();
    }
  }, [
    documentInputValue.documentTitle,
    documentTitleResult,
    isLoadingDocumentTitleResult,
  ]);

  // use refs (for external control)
  const refsMap = objectMap(defaultDocumentInputValue, () =>
    useRef<BaseInputRefAttributes>(null),
  );
  const createNewProjectTitleRef = useRef<BaseInputRefAttributes>(null);

  // Handle new project title creation
  useEffect(() => {
    if (createNewProjectTitleVisible) {
      createNewProjectTitleRef.current?.focus();
    }
  }, [createNewProjectTitleVisible]);

  useEffect(() => {
    if (
      documentFormIsModified.current.status &&
      documentInputValue.status === DocumentStatus.EXPIRED &&
      documentFormIsModified.current.documentExpirationDate
    ) {
      // if status is changed to EXPIRED and documentExpirationDate is modified
      // then reset documentExpirationDate
      refsMap.documentExpirationDate?.current?.reset();
    }
  }, [documentInputValue.status]);

  function handleNewProjectTitleChange(
    _: DocumentFormFieldId,
    detail: InputChangeDetail<DocumentInputValue["projectId"]>,
  ) {
    newProjectTitle.current = detail.value;
    isNewProjectTitleError.current = detail.isError;
    debounceProjectTitleExists(detail.value);
  }

  function handleCancelCreateNewProjectTitle() {
    createNewProjectTitleRef.current?.reset();
  }

  function handleCreateNewProjectTitle() {
    createNewProjectTitleRef.current?.validate();
    if (!isNewProjectTitleError.current) {
      refsMap.projectId?.current?.overrideValue?.(newProjectTitle.current);
      refsMap.projectDescription?.current?.overrideValue?.("");
      setNewProjectTitleOption({
        value: newProjectTitle.current,
        label: newProjectTitle.current,
      });
      setCreateNewProjectTitleVisible(false);
      createNewProjectTitleRef.current?.reset();
    }
  }

  // Handle form changes
  function handleChange(
    fieldId: DocumentFormFieldId,
    detail: InputChangeDetail<DocumentInputValue[DocumentFormFieldId]>,
  ) {
    setDocumentInputValue((prev) => ({
      ...prev,
      [fieldId]: detail.value,
    }));
    documentFormIsError.current = {
      ...documentFormIsError.current,
      [fieldId]: detail.isError,
    };
    documentFormIsModified.current = {
      ...documentFormIsModified.current,
      [fieldId]: detail.isModified,
    };

    if (fieldId === "documentTitle") {
      debounceDocumentTitleToCheck(detail.value as string);
    }
  }

  function createNewProjectModal(
    newProjectTitleInputs: FormInputsComponentWithProps<
      AutosuggestInputProps<DocumentFormFieldId>
    >,
  ) {
    const {
      component: DocumentCreateNewProjectTitleInput,
      props: documentCreateNewProjectTitleInputProps,
    } = newProjectTitleInputs;

    return (
      <CustomModal
        data-testid="document-form-create-new-project-modal"
        visible={createNewProjectTitleVisible}
        setVisible={setCreateNewProjectTitleVisible}
        header={CREATE_NEW_PROJECT_INPUT}
        primaryActionButtonText="Ok"
        onPrimaryAction={handleCreateNewProjectTitle}
        onDismissOrCancel={handleCancelCreateNewProjectTitle}
      >
        <DocumentCreateNewProjectTitleInput
          {...documentCreateNewProjectTitleInputProps}
          data-testid="document-form-create-new-project-input"
          ref={createNewProjectTitleRef}
          onChange={handleNewProjectTitleChange}
        />
      </CustomModal>
    );
  }

  return {
    handleChange,
    createNewProjectModal,
    refsMap,
    defaultDocumentInputValue,
    documentInputValue,
    documentFormIsError,
    documentFormIsModified,
    newProjectTitleOption,
    documentTitleResult,
    documentTitleExists,
    isLoadingDocumentTitleResult,
    projectTitleExists,
    handleCreateNewProject: () => setCreateNewProjectTitleVisible(true),
  };
}
