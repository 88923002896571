export const GET_PROJECT_ERROR = "Error getting project";
export const GET_PROJECTS_ERROR = "Error loading projects";
export const CREATE_PROJECT_ERROR = "Error creating project";
export const UPDATE_PROJECT_ERROR = "Error updating project";
export const DELETE_PROJECT_ERROR = "Error deleting project";
export const UPLOAD_DOCUMENT_FILE_ERROR = "Error uploading document file";
export const GET_DOCUMENTS_ERROR = "Error loading documents";
export const CREATE_DOCUMENT_ERROR = "Error creating document";
export const UPDATE_DOCUMENT_ERROR = "Error updating document";
export const DELETE_DOCUMENT_ERROR = "Error deleting document";
export const DOWNLOAD_DOCUMENT_ERROR = "Error downloading document";
export const EXPORT_DOCUMENT_METADATA_ERROR =
  "Error exporting document metadata";
export const GET_DOCUMENT_METADATA_OPTION_ERROR =
  "Error getting document metadata options";
export const GENERATE_DOCUMENT_METADATA_ERROR =
  "Error generating document metadata";
