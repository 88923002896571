import { MAX_FILE_SIZE_IN_BYTES_FOR_GEN_AI_METADATA_GENERATION } from "../../../data/constants/common";
import { toFileSize } from "../../../helpers";

interface GetGenerateMetadataButtonDisabledStatusProps {
  documentFormIsModifiedFileName?: boolean;
  documentInputValueFileName: File[];
  metadataAlreadyGenerated: boolean;
}

interface GetGenerateMetadataButtonDisabledStatusOutput {
  disabled: boolean;
  disabledReason: string;
}

export function getGenerateMetadataButtonDisabledStatus({
  documentFormIsModifiedFileName: documentFormIsModifiedFileName,
  documentInputValueFileName: documentInputValueFileName,
  metadataAlreadyGenerated,
}: GetGenerateMetadataButtonDisabledStatusProps): GetGenerateMetadataButtonDisabledStatusOutput {
  return {
    disabled:
      documentInputValueFileName.length === 0 ||
      !documentFormIsModifiedFileName ||
      documentInputValueFileName[0].size >=
        MAX_FILE_SIZE_IN_BYTES_FOR_GEN_AI_METADATA_GENERATION ||
      metadataAlreadyGenerated,
    disabledReason:
      documentInputValueFileName.length === 0
        ? "Please upload a file to generate metadata."
        : !documentFormIsModifiedFileName
          ? "Please upload a new file to generate metadata."
          : documentInputValueFileName[0].size >=
              MAX_FILE_SIZE_IN_BYTES_FOR_GEN_AI_METADATA_GENERATION
            ? `Metadata generation is only supported for document with size less than ${toFileSize(MAX_FILE_SIZE_IN_BYTES_FOR_GEN_AI_METADATA_GENERATION)}`
            : "Metadata is already generated for this document.",
  };
}
