import React, { FC, useEffect } from "react";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Form from "@cloudscape-design/components/form";
import { Document, DocumentStatus, Project } from "../../client/interfaces";
import {
  useDocumentMetadataOptions,
  useFlashbarMessages,
  useProjects,
  useSettingsWithAuthConfigured,
} from "../../hooks";
import FlashBarMessages from "../../components/common/FlashbarMessages";
import {
  GET_DOCUMENT_METADATA_OPTION_ERROR,
  GET_PROJECTS_ERROR,
} from "../../data/constants/errorMessage";
import { DocumentMetadataName } from "../common/documentConstant";
import { useDocumentYearQuarterMonthOptions } from "../common/documentOption";
import { useMetadataOptions } from "../common/form/metadataOptions";
import {
  DOCUMENT_FORM_GENERAL_INFORMATION,
  DOCUMENT_FORM_INPUTS,
  DOCUMENT_FORM_ADDITIONAL_INFORMATION,
  DocumentFormInputsProps,
  CREATE_NEW_PROJECT_INPUT,
  DOCUMENT_FORM_PROJECT_INFORMATION,
  DocumentFormSubmitAction,
  DocumentFormSubmitDetail,
  useDocumentFormChange,
  useDocumentFormSubmit,
  getGenerateMetadataButtonDisabledStatus,
} from "./documentForm";
import styles from "./DocumentForm.module.scss";
import GenerateDocumentMetadataAlert from "./documentForm/DocumentFormGenerateMetadataAlert";

export interface DocumentFormProps {
  /**
   * Default project to populate the inputs.
   */
  project?: Project;
  /**
   * Default document to populate the inputs.
   */
  document?: Document;
  onCancel?: () => void;
  onSubmit?: (detail: DocumentFormSubmitDetail) => void;
  submitAction?: DocumentFormSubmitAction;
}

const DocumentForm: FC<DocumentFormProps> = ({
  project,
  document,
  onCancel,
  onSubmit,
  submitAction = DocumentFormSubmitAction.CREATE,
}) => {
  const {
    data: documentMetadataOptions,
    error: getDocumentMetadataOptionError,
  } = useDocumentMetadataOptions();
  const { data: projects, error: getProjectsError } = useProjects();
  const { data: settings } = useSettingsWithAuthConfigured();

  const { messages, addErrorMessage } = useFlashbarMessages();

  // Document year/quarter/month states
  const {
    setDocumentYearQuarterMonthFilteringText:
      setDocumentYearQuarterMonthFilteringText,
    documentYearQuarterMonthOptions,
  } = useDocumentYearQuarterMonthOptions();

  const {
    handleChange,
    createNewProjectModal,
    refsMap,
    defaultDocumentInputValue,
    documentInputValue,
    documentFormIsError,
    documentFormIsModified,
    newProjectTitleOption,
    documentTitleResult,
    documentTitleExists,
    isLoadingDocumentTitleResult,
    projectTitleExists,
    handleCreateNewProject,
  } = useDocumentFormChange({
    projects,
    project,
    document,
    submitAction,
    validateDocumentTitle: (title) =>
      documentFormInputs[DocumentMetadataName.DOCUMENT_TITLE].props.validate?.(
        title,
      ) ?? { isValid: true },
    validateNewProjectTitle: (title) =>
      documentFormInputs[CREATE_NEW_PROJECT_INPUT].props.validate?.(title) ?? {
        isValid: true,
      },
  });

  const { metadataOptions } = useMetadataOptions({
    projects,
    documentMetadataOptions,
    newProjectTitle: newProjectTitleOption,
    documentTitles: documentTitleResult,
    documentYearQuarterMonthOptions,
  });

  const {
    handleSubmit,
    isSubmitting,
    replaceDocumentModal,
    handleGenerateDocumentMetadata,
    isGeneratingDocumentMetadata,
    generatedDocumentMetadata,
    metadataAlreadyGenerated,
  } = useDocumentFormSubmit({
    document,
    documentInputValue,
    documentFormIsError,
    documentFormIsModified,
    newProjectTitleOption,
    marketplaceSequence: metadataOptions?.marketplaces?.map(
      ({ value }) => value,
    ),
    submitAction,
    onSubmitAction: () =>
      Object.values(refsMap).forEach((ref) => {
        ref.current?.validate();
      }),
    onSubmitSuccess: onSubmit,
    onSubmitError: focusTopMostError,
    addErrorMessage,
  });

  useEffect(() => {
    if (getProjectsError) {
      addErrorMessage(getProjectsError, GET_PROJECTS_ERROR);
    }
    if (getDocumentMetadataOptionError) {
      addErrorMessage(
        getDocumentMetadataOptionError,
        GET_DOCUMENT_METADATA_OPTION_ERROR,
      );
    }
  }, [getDocumentMetadataOptionError, getProjectsError]);

  function handleCancel() {
    Object.values(refsMap).forEach((ref) => {
      ref.current?.reset();
    });
    onCancel?.();
  }

  function focusTopMostError() {
    for (const input of [
      ...projectInformationInputs,
      ...generalInformationInputs,
      ...additionalInformationInputs,
    ]) {
      if (documentFormIsError.current[input.props.fieldId]) {
        return refsMap[input.props.fieldId]?.current?.focus();
      }
    }
  }

  // Create all form input components and props
  // Include all external status control, validation, and form options here
  const documentFormInputsProps: DocumentFormInputsProps = {
    options: metadataOptions,
    defaultValue: defaultDocumentInputValue,
    readOnly: {
      projectDescription:
        Boolean(documentInputValue.projectId) &&
        documentInputValue.projectId !== newProjectTitleOption?.value,
    },
    disabled: {
      status:
        submitAction === DocumentFormSubmitAction.CREATE ||
        submitAction === DocumentFormSubmitAction.DUPLICATE,
      documentExpirationDate:
        documentInputValue.status === DocumentStatus.EXPIRED,
    },
    externalValidate: {
      projectId: () => !projectTitleExists.current,
      documentTitle: () => !documentTitleExists.current,
      documentExpirationDate: () =>
        documentInputValue.status === DocumentStatus.EXPIRED ||
        !documentFormIsModified.current.documentExpirationDate,
    },
    manualFilterOptions: {
      documentTitle: {
        loading: isLoadingDocumentTitleResult,
      },
      documentYearQuarterMonth: {
        onLoadItems: ({ detail: { filteringText } }) => {
          setDocumentYearQuarterMonthFilteringText(filteringText);
        },
      },
    },
    generatedDocumentMetadata,
  };
  const documentFormInputs = DOCUMENT_FORM_INPUTS(documentFormInputsProps);

  const projectInformationInputs = DOCUMENT_FORM_PROJECT_INFORMATION(
    documentFormInputsProps,
  );
  const generalInformationInputs = DOCUMENT_FORM_GENERAL_INFORMATION(
    documentFormInputsProps,
  );
  const additionalInformationInputs = DOCUMENT_FORM_ADDITIONAL_INFORMATION(
    documentFormInputsProps,
  );

  const metadataButtonDisabledStatus = getGenerateMetadataButtonDisabledStatus({
    documentFormIsModifiedFileName: documentFormIsModified.current.fileName,
    documentInputValueFileName: documentInputValue.fileName,
    metadataAlreadyGenerated,
  });

  return (
    <div data-testid="document-form" className={styles.documentForm}>
      <Form
        header={
          <FlashBarMessages
            data-testid="document-form-flashbar-messages"
            data-document-form-selector="flashbar-messages"
            messages={messages}
          />
        }
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              data-testid="document-form-cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              data-testid="document-form-submit-button"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              {submitAction === DocumentFormSubmitAction.DUPLICATE
                ? DocumentFormSubmitAction.CREATE
                : submitAction}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Container
            data-testid="document-form-project-information"
            header={<Header variant="h3">Project information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {projectInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  <div key={fieldId}>
                    {
                      // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_PROJECT_INFORMATION`
                      <DocumentFormComponent
                        {...rest}
                        data-testid={`document-form-${fieldId}`}
                        fieldId={fieldId}
                        ref={refsMap[fieldId]}
                        onChange={handleChange}
                      />
                    }
                    {fieldId === "projectId" ? (
                      <Button
                        data-testid="document-form-create-new-project-button"
                        data-document-form-selector="in-form-button"
                        variant="normal"
                        onClick={handleCreateNewProject}
                      >
                        Create new project
                      </Button>
                    ) : undefined}
                  </div>
                );
              })}
            </SpaceBetween>
          </Container>
          <Container
            data-testid="document-form-general-information"
            header={<Header variant="h3">General information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {generalInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  <div key={fieldId}>
                    {
                      // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_GENERAL_INFORMATION`
                      <DocumentFormComponent
                        {...rest}
                        data-testid={`document-form-${fieldId}`}
                        fieldId={fieldId}
                        ref={refsMap[fieldId]}
                        onChange={handleChange}
                      />
                    }
                    {fieldId === "fileName" &&
                      settings?.FEATURE_FLAGS_enableMetadataGeneration && (
                      <SpaceBetween direction="vertical" size="s">
                        <Button
                          data-testid="document-form-generate-metadata-button"
                          data-document-form-selector="in-form-button"
                          iconName="gen-ai"
                          variant="normal"
                          disabled={metadataButtonDisabledStatus.disabled}
                          loading={isGeneratingDocumentMetadata}
                          disabledReason={
                            metadataButtonDisabledStatus.disabledReason
                          }
                          onClick={handleGenerateDocumentMetadata}
                        >
                            Generate metadata
                        </Button>
                        <GenerateDocumentMetadataAlert
                          isLoading={isGeneratingDocumentMetadata}
                          isSuccess={Boolean(generatedDocumentMetadata)}
                          fileName={
                            documentInputValue.fileName.length > 0
                              ? documentInputValue.fileName[0].name
                              : undefined
                          }
                          submitAction={submitAction}
                        />
                      </SpaceBetween>
                    )}
                  </div>
                );
              })}
            </SpaceBetween>
          </Container>
          <Container
            data-testid="document-form-additional-information"
            header={<Header variant="h3">Additional information</Header>}
          >
            <SpaceBetween direction="vertical" size="m">
              {additionalInformationInputs.map((input) => {
                const {
                  component: DocumentFormComponent,
                  props: { fieldId, ...rest },
                } = input;
                return (
                  // @ts-expect-error attributes have correct types, which are enforced in `DOCUMENT_FORM_ADDITIONAL_INFORMATION`
                  <DocumentFormComponent
                    {...rest}
                    data-testid={`document-form-${fieldId}`}
                    fieldId={fieldId}
                    key={fieldId}
                    ref={refsMap[fieldId]}
                    onChange={handleChange}
                  />
                );
              })}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
      {createNewProjectModal(documentFormInputs[CREATE_NEW_PROJECT_INPUT])}
      {replaceDocumentModal}
    </div>
  );
};

export default DocumentForm;
