import { AppLayoutProps } from "@cloudscape-design/components/app-layout";
import { NonCancelableCustomEvent } from "@cloudscape-design/components/interfaces";
import { ReactNode, useEffect, useRef, useState, EffectCallback } from "react";
import isEqual from "lodash.isequal";
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { v4 as uuidv4 } from "uuid";
import { ButtonProps } from "@cloudscape-design/components/button";
import { DEFAULT_SPLIT_PANEL_WIDTH_PIXELS } from "../data/constants/common";
import { BaseInputProps } from "../components/inputs";
import { handleError } from "../helpers";
import { useLocalStorage, useSetLocalStorage } from "./browser";

/**
 * Use split panel hook.
 * @param panelSizeStorageKey if provided, persist split panel size to local storage
 */
export const useSplitPanel = (panelSizeStorageKey?: string) => {
  const [splitPanelSize, setSplitPanelSize] = useState(
    DEFAULT_SPLIT_PANEL_WIDTH_PIXELS,
  );
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);

  const { data: localStoragePanelSize } =
    useLocalStorage<AppLayoutProps.SplitPanelResizeDetail>(
      panelSizeStorageKey ?? "Dummy",
    );
  const { trigger: setLocalStoragePanelSize } =
    useSetLocalStorage<AppLayoutProps.SplitPanelResizeDetail>(
      panelSizeStorageKey ?? "Dummy",
    );

  useEffect(() => {
    if (panelSizeStorageKey) {
      setSplitPanelSize(
        localStoragePanelSize?.size ?? DEFAULT_SPLIT_PANEL_WIDTH_PIXELS,
      );
    }
  }, [localStoragePanelSize]);

  const onSplitPanelResize = ({
    detail: { size },
  }: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(size);
    if (panelSizeStorageKey) {
      setLocalStoragePanelSize({ size });
    }
  };

  const onSplitPanelToggle = ({
    detail: { open },
  }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(open);
  };

  return {
    splitPanelOpen,
    setSplitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
  };
};

interface UseInputProps<T> {
  validate?: BaseInputProps<T>["validate"];
  initialState: T;
}

interface HandleInputChangeOutput {
  isValid: boolean;
  isModified: boolean;
}

export function useInput<T>({ validate, initialState }: UseInputProps<T>) {
  const [value, setValue] = useState(initialState);
  const [errorText, setErrorText] = useState<ReactNode>();
  const initialValue = useRef(initialState);
  const valueRef = useRef(initialState);

  const handleInputChange = (value: T): HandleInputChangeOutput => {
    setValue(value);
    valueRef.current = value;
    const isModified = !isEqual(value, initialValue.current);
    const { isValid, errorText } = validate?.(value) ?? { isValid: true };
    if (isValid) {
      setErrorText(errorText);
    }
    return { isValid, isModified };
  };

  const handleBlur = () => {
    const { errorText } = validate?.(valueRef.current) ?? { isValid: true };
    setErrorText(errorText);
  };

  const resetInput = () => {
    setValue(initialValue.current);
    valueRef.current = initialValue.current;
    setErrorText(undefined);
  };

  return {
    value,
    errorText,
    handleInputChange,
    handleBlur,
    resetInput,
  };
}

export function useFlashbarMessages(
  initialMessages: FlashbarProps.MessageDefinition[] = [],
) {
  const [messages, setMessages] = useState<FlashbarProps.MessageDefinition[]>(
    initialMessages.map(toMessage),
  );

  function toMessage(message: FlashbarProps.MessageDefinition) {
    const id = `message_${uuidv4()}`;
    return {
      id: id,
      dismissLabel: "Dismiss message",
      dismissible: true,
      onDismiss: () => removeMessage(id),
      ...message,
    } as FlashbarProps.MessageDefinition;
  }

  const addMessage = (message: FlashbarProps.MessageDefinition) => {
    setMessages([toMessage(message), ...messages]);
  };

  const addErrorMessage = (error: unknown, messageTitle?: string) => {
    const message: FlashbarProps.MessageDefinition = {
      type: "error",
      header: "Error!",
      content: handleError(error, messageTitle),
    };
    addMessage(message);
  };

  const removeMessage = (id: string) => {
    setMessages((items) => items.filter((item) => item.id !== id));
  };

  const removeAllMessages = () => setMessages([]);

  return {
    messages,
    addMessage,
    addErrorMessage,
    removeAllMessages,
  };
}

export function useFocusButton<T extends ButtonProps.Ref = ButtonProps.Ref>() {
  const buttonRef = useRef<T>(null);
  const [shouldFocusButton, setShouldFocusButton] = useState(false);

  useEffect(() => {
    if (shouldFocusButton) {
      buttonRef.current?.focus();
    }
    return () => {
      setShouldFocusButton(false);
    };
  }, [shouldFocusButton, buttonRef]);

  const focusButton = () => {
    setShouldFocusButton(true);
  };

  return { buttonRef, focusButton };
}

interface Retry {
  /**
   * Delay in milliseconds between each polling attempt.
   * @defaultValue 5000
   */
  delayInMillis?: number;
  /**
   * Number of polling attempts before giving up.
   * @defaultValue 10
   */
  retryLimit?: number;
  /**
   * Flag to control whether to start polling immediately.
   * @defaultValue `true` to start immediately
   */
  startPolling?: boolean;
  /**
   * Flag to control whether to stop polling immediately.
   * @defaultValue `false` to never stop polling until component unmounts
   */
  stopPolling?: boolean;

  /**
   * Callback to run when polling stops.
   */
  onStopPolling?: () => void;
}

/**
 * Custom hook that can be used to poll a function periodically.
 * @param callback - callback to run during polling
 * @param retry
 *
 * @example
 * usePolling(
 *	() => {
 *		console.log('polling latest information');
 *	},
 *	{
 *		delayInMillis: 5000,
 *		retryLimit: 10,
 *		startPolling: true,
 *		stopPolling: false,
 *		onStopPolling: () => {
 *			console.log("Polling stopped");
 *		},
 *	}
 * );
 */
export function usePolling<T extends EffectCallback>(
  callback: T,
  retry?: Retry,
) {
  const {
    delayInMillis = 5000,
    retryLimit = 10,
    startPolling = true,
    stopPolling = false,
    onStopPolling,
  } = retry ?? {};
  const savedCallback = useRef<T>();
  const stopPollingCallback = useRef<() => void>();
  const retryCount = useRef(0);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    stopPollingCallback.current = onStopPolling;
  }, [onStopPolling]);

  // Set up the interval.
  useEffect(() => {
    let id: ReturnType<typeof setInterval>;
    if (startPolling) {
      const pollingCallback = () => {
        if (savedCallback.current) {
          savedCallback.current();
          retryCount.current += 1;
        }
        if (retryCount.current >= retryLimit || stopPolling) {
          clearInterval(id);
          retryCount.current = 0;
          stopPollingCallback.current?.();
        }
      };
      id = setInterval(pollingCallback, delayInMillis);
    }
    return () => clearInterval(id);
  }, [delayInMillis, retryLimit, startPolling, stopPolling]);
}
