import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import Select, { SelectProps } from "@cloudscape-design/components/select";
import { useInput } from "../../hooks";
import { arrayToDict, flatten } from "../../helpers";
import CustomFormField from "./CustomFormField";
import { GenAiOutputProps, useGenAiOutput } from "./genai";
import styles from "./Input.module.scss";
import {
  BaseInputProps,
  BaseInputRefAttributes,
  BaseOptionDefinition,
  BaseOptionGroup,
  BaseSelectProps,
} from ".";

export interface SingleSelectInputProps<K extends string = string>
  extends BaseInputProps<string, K>,
    GenAiOutputProps<string>,
    BaseSelectProps {}

function SingleSelectInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    options,
    filteringType = "auto",
    constraintText,
    genAiOutputValue,
    ...props
  }: SingleSelectInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const { value, errorText, handleInputChange, handleBlur, resetInput } =
    useInput<string>({
      validate,
      initialState: defaultValue,
    });

  const optionsByValue = useMemo(() => {
    const optionDefinitions = flatten<
      BaseOptionDefinition,
      BaseOptionGroup,
      "options"
    >(options ?? [], "options");
    return arrayToDict(optionDefinitions ?? [], "value");
  }, [options]);

  const inputRef = useRef<SelectProps.Ref>(null);

  const { genAiOutputElement: GenAiOutputElement } = useGenAiOutput({
    validate,
    genAiOutputValue,
    currentInput: value,
    setInput: handleChange,
    resetInput,
    identifier: fieldId,
    label,
  });

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetInput();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleBlur,
      focus: () => inputRef.current?.focus(),
      overrideValue: (value: string) => {
        handleChange(value);
      },
    };
  }, [handleBlur, inputRef]);

  function handleChange(value: string) {
    const { isValid, isModified } = handleInputChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
  }

  return (
    <>
      <CustomFormField
        label={label}
        required={required}
        description={description}
        errorText={errorText}
        constraintText={constraintText}
      >
        <Select
          selectedOption={
            value !== undefined ? (optionsByValue[value] ?? null) : null
          }
          options={options}
          filteringType={filteringType}
          ariaRequired={required}
          filteringAriaLabel="Filter"
          filteringClearAriaLabel="Clear"
          selectedAriaLabel="Selected"
          placeholder={placeholder}
          onChange={({ detail: { selectedOption } }) => {
            handleChange((selectedOption as BaseOptionDefinition).value);
          }}
          onBlur={handleBlur}
          disabled={disabled}
          ref={inputRef}
          {...props}
        />
      </CustomFormField>
      <div className={styles.genAiOutputPadding}>{GenAiOutputElement}</div>
    </>
  );
}

export default forwardRef(SingleSelectInput);

export function TypedSingleSelectInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, SingleSelectInputProps<K>>(
    SingleSelectInput,
  );
}
