import { useEffect, useState } from "react";
import { useDownloadDocumentLink } from "../../hooks";
import { MessagesContextType } from "../../context/MessagingContext";
import { DOWNLOAD_DOCUMENT_ERROR } from "../../data/constants/errorMessage";

interface UseDocumentDownloadProps {
  addErrorMessage?: MessagesContextType["addErrorMessage"];
}

export function useDocumentDownload({
  addErrorMessage,
}: UseDocumentDownloadProps) {
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);
  const [isDownload, setIsDownload] = useState<boolean | undefined>(undefined);
  const {
    data: downloadLink,
    isLoading,
    error,
  } = useDownloadDocumentLink(projectId, documentId, isDownload);

  useEffect(() => {
    if (downloadLink) {
      location.replace(downloadLink);
      setProjectId(undefined);
      setDocumentId(undefined);
    }
  }, [downloadLink]);

  useEffect(() => {
    if (error) {
      addErrorMessage?.(error.message, DOWNLOAD_DOCUMENT_ERROR);
    }
  }, [error]);

  function downloadDocument(
    projectId: string,
    documentId: string,
    isDownload?: boolean,
  ) {
    setProjectId(projectId);
    setDocumentId(documentId);
    setIsDownload(isDownload);
  }

  return {
    isLoading,
    downloadDocument,
  };
}
