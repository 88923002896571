import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { NonCancelableCustomEvent } from "@cloudscape-design/components/interfaces";
import {
  useDocumentMetadataOptions,
  useGetDocumentTitles,
} from "../../../hooks";
import {
  DEFAULT_DEBOUNCE_DELAY_MILLIS,
  MINIMUM_DOCUMENT_TITLE_LENGTH,
} from "../../../data/constants/common";
import { useDocumentYearQuarterMonthOptions } from "../../common/documentOption";
import {
  GetProjectsCommandInput,
  Project,
  SemanticSearchInput,
} from "../../../client/interfaces";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../../common/documentConstant";
import { GET_DOCUMENT_METADATA_OPTION_ERROR } from "../../../data/constants/errorMessage";
import { MessagesContextType } from "../../../context/MessagingContext";
import {
  isSemanticSearch,
  SEMANTIC_SEARCH_ENABLED_QUERY_KEY,
  SEMANTIC_SEARCH_ENABLED_QUERY_VALUE,
  toProjectsFilter,
  toPropertyFilterQuery,
  toURLSearchParams,
} from "./projectDashboardFilterConversion";
import {
  PROJECT_TABLE_FILTERING_OPTIONS,
  PROJECT_TABLE_FILTERING_PROPERTIES,
} from "./projectDashboardTableConfig";

interface UseProjectDashboardFilterProps {
  alias?: string;
  isEditor?: boolean;
  addErrorMessage?: MessagesContextType["addErrorMessage"];
  FEATURE_FLAGS_enableSemanticSearch?: boolean;
}

export function useProjectDashboardFilter({
  alias,
  isEditor,
  addErrorMessage,
  FEATURE_FLAGS_enableSemanticSearch,
}: UseProjectDashboardFilterProps = {}) {
  // Metadata options
  const { data: metadataOptions, error: getDocumentMetadataOptionsError } =
    useDocumentMetadataOptions();

  // Document title options
  const [documentTitleToCheck, setDocumentTitleToCheck] = useState<
    string | undefined
  >(undefined);
  const { data: documentTitleResult, isLoading: isLoadingDocumentTitleResult } =
    useGetDocumentTitles(documentTitleToCheck);
  const debounceDocumentTitleToCheck = useDebouncedCallback(
    (value?: string) =>
      setDocumentTitleToCheck(
        (value ?? "").length >= MINIMUM_DOCUMENT_TITLE_LENGTH
          ? value
          : undefined,
      ),
    DEFAULT_DEBOUNCE_DELAY_MILLIS,
  );

  // Document year/quarter/month options
  const {
    setDocumentYearQuarterMonthFilteringText,
    documentYearQuarterMonthOptions,
  } = useDocumentYearQuarterMonthOptions();

  // Filter parameters
  const [searchParams, setSearchParams] = useSearchParams();
  const [getProjectsFilter, setGetProjectsFilter] =
    useState<GetProjectsCommandInput>();
  const [semanticSearchFilter, setSemanticSearchFilter] =
    useState<SemanticSearchInput>();
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and",
  });
  const semanticSearchEnabled = isSemanticSearch(searchParams);

  useEffect(() => {
    if (getDocumentMetadataOptionsError) {
      addErrorMessage?.(
        getDocumentMetadataOptionsError,
        GET_DOCUMENT_METADATA_OPTION_ERROR,
      );
    }
  }, [getDocumentMetadataOptionsError]);

  useEffect(() => {
    if (!FEATURE_FLAGS_enableSemanticSearch) {
      searchParams.delete(SEMANTIC_SEARCH_ENABLED_QUERY_KEY);
    }
    const updatedQuery = toPropertyFilterQuery(searchParams);
    setQuery(updatedQuery);
    const projectsFilter = toProjectsFilter(searchParams);
    if (projectsFilter.semanticSearch) {
      setGetProjectsFilter(undefined);
      setSemanticSearchFilter(projectsFilter.filter);
    } else {
      setGetProjectsFilter(projectsFilter.filter);
      setSemanticSearchFilter(undefined);
    }
    handleFilterChange(updatedQuery, projectsFilter.semanticSearch);
  }, [searchParams]);

  function handleOnFilteringLoadItems({
    detail: { filteringText, filteringProperty },
  }: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>) {
    if (
      filteringProperty?.key ===
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TITLE]
    ) {
      debounceDocumentTitleToCheck(filteringText);
    }
    if (
      filteringProperty?.key ===
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH]
    ) {
      setDocumentYearQuarterMonthFilteringText(filteringText);
    }
  }

  function handleFilterChange(
    query: PropertyFilterProps.Query,
    semanticSearch: boolean,
  ) {
    setSearchParams(toURLSearchParams(query, semanticSearch));
  }

  function handleSemanticSearchToggle(checked: boolean) {
    if (checked) {
      searchParams.set(
        SEMANTIC_SEARCH_ENABLED_QUERY_KEY,
        SEMANTIC_SEARCH_ENABLED_QUERY_VALUE,
      );
      setSearchParams(searchParams, { replace: true });
    } else {
      searchParams.delete(SEMANTIC_SEARCH_ENABLED_QUERY_KEY);
      setSearchParams(searchParams, { replace: true });
    }
  }

  const filteringPlaceholder = semanticSearchEnabled
    ? "Find projects by entering search phrase"
    : "Find projects by entering texts, keywords, property, or value";

  const filteringProperties = semanticSearchEnabled
    ? []
    : PROJECT_TABLE_FILTERING_PROPERTIES;

  const filteringOptions = (projects?: Project[]) =>
    semanticSearchEnabled
      ? []
      : PROJECT_TABLE_FILTERING_OPTIONS({
        options: {
          ...metadataOptions,
          documentYearQuarterMonth: documentYearQuarterMonthOptions,
          projects: projects?.map((project) => ({
            value: project.projectTitle,
          })),
          authors: alias && isEditor ? [{ value: alias }] : [],
          documentTitles: documentTitleResult?.map((title) => ({
            value: title,
          })),
        },
      });

  const copyUrlDisplayText =
    query.tokens.length === 0
      ? "Copy URL"
      : semanticSearchEnabled
        ? "Copy URL with search phrase"
        : "Copy URL with current filters";

  return {
    handleFilterChange,
    query,
    setQuery,
    getProjectsFilter,
    filteringPlaceholder,
    filteringProperties,
    filteringOptions,
    handleOnFilteringLoadItems,
    filteringLoading: isLoadingDocumentTitleResult,
    semanticSearchEnabled,
    handleSemanticSearchToggle,
    semanticSearchFilter,
    copyUrlDisplayText,
  };
}
