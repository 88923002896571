import React, { useState } from "react";
import Box from "@cloudscape-design/components/box";
import Table, { TableProps } from "@cloudscape-design/components/table";
import {
  PropertyFilterOption,
  PropertyFilterQuery,
  useCollection,
} from "@cloudscape-design/collection-hooks";
import CopyToClipboard from "@cloudscape-design/components/copy-to-clipboard";
import { DEFAULT_PAGE_SIZE } from "../../data/constants/common";
import TablePagination from "./TablePagination";
import TableCollectionPreferences, {
  TableCollectionPreferencesProps,
} from "./TableCollectionPreferences";
import TablePropertyFilter, {
  TablePropertyFilterProps,
} from "./TablePropertyFilter";
import styles from "./ServerClientHybridCollectionTable.module.css";

export interface ServerClientHybridCollectionTableProps<T>
  extends Pick<
      TableProps<T>,
      | "items"
      | "loading"
      | "variant"
      | "contentDensity"
      | "selectionType"
      | "selectedItems"
      | "onSelectionChange"
      | "columnDefinitions"
      | "empty"
      | "header"
      | "trackBy"
      | "sortingColumn"
      | "sortingDescending"
      | "onColumnWidthsChange"
    >,
    Partial<TableCollectionPreferencesProps>,
    Partial<TablePropertyFilterProps> {
  clientFilteringFunction?: (item: T, query: PropertyFilterQuery) => boolean;
  clientFilteringOptions?: readonly PropertyFilterOption[];
  withClientSideFiltering?: boolean;
  withClientSideSorting?: boolean;
  withClientSidePagination?: boolean;
  copyUrlProps?: { textToCopy: string; displayText?: string };
}

function ServerClientHybridCollectionTable<T>({
  items,
  variant,
  contentDensity,
  selectionType,
  selectedItems,
  onSelectionChange,
  columnDefinitions,
  preferences,
  onPreferencesConfirm,
  contentDisplayOptions,
  onFilterChange,
  filteringProperties,
  filteringOptions,
  filteringPlaceholder,
  onFilteringLoadItems,
  filteringLoading,
  filteringError,
  itemMatchCount,
  queryOverride,
  setQueryOverride,
  header,
  empty,
  trackBy,
  sortingColumn,
  sortingDescending = true,
  onColumnWidthsChange,
  clientFilteringFunction,
  clientFilteringOptions,
  withClientSideFiltering,
  withClientSideSorting,
  withClientSidePagination,
  copyUrlProps,
  customControl,
  reverseCustomControlDisplayOrder,
  ...props
}: ServerClientHybridCollectionTableProps<T>): JSX.Element {
  const [internalSelectedItems, setInternalSelectedEntities] = useState<T[]>(
    [],
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    items: pageItems,
    collectionProps,
    paginationProps: { pagesCount, currentPageIndex, ...restPaginationProps },
    propertyFilterProps: {
      filteringProperties: clientFilteringProperties,
      filteringOptions: defaultClientFilteringOptions,
      ...restPropertyFilterProps
    },
    filteredItemsCount,
  } = useCollection(items, {
    sorting: sortingColumn
      ? { defaultState: { sortingColumn, isDescending: sortingDescending } }
      : {},
    pagination: {
      pageSize: preferences?.pageSize ?? DEFAULT_PAGE_SIZE,
    },
    propertyFiltering: {
      filteringFunction: clientFilteringFunction,
      filteringProperties: filteringProperties ?? [],
      empty: (
        <Box textAlign="center" color="inherit">
          {empty}
        </Box>
      ),
      noMatch: (
        <Box textAlign="center" color="inherit">
          {empty}
        </Box>
      ),
    },
  });

  return (
    <div
      className={selectionType === "single" ? styles.hideSelectAllButton : ""}
    >
      <Table
        data-testid="server-client-hybrid-collection-table"
        data-table-selector="server-client-hybrid-collection-table"
        variant={variant}
        contentDensity={contentDensity}
        header={header}
        loadingText="Loading..."
        filter={
          <>
            {filteringProperties && (
              <TablePropertyFilter
                customControl={customControl}
                reverseCustomControlDisplayOrder={
                  reverseCustomControlDisplayOrder
                }
                filteringProperties={
                  withClientSideFiltering
                    ? clientFilteringProperties
                    : filteringProperties
                }
                filteringOptions={
                  withClientSideFiltering
                    ? (clientFilteringOptions ?? defaultClientFilteringOptions)
                    : filteringOptions
                }
                filteringPlaceholder={filteringPlaceholder}
                onFilterChange={onFilterChange}
                queryOverride={queryOverride}
                setQueryOverride={setQueryOverride}
                itemMatchCount={
                  withClientSideFiltering ? filteredItemsCount : itemMatchCount
                }
                filteringLoading={filteringLoading}
                filteringError={filteringError}
                onFilteringLoadItems={onFilteringLoadItems}
                {...(withClientSideFiltering ? restPropertyFilterProps : {})}
              />
            )}
            {copyUrlProps && (
              <Box>
                <CopyToClipboard
                  data-testid="copy-url-to-clipboard"
                  copyButtonText={copyUrlProps.displayText}
                  copyErrorText="URL failed to copy"
                  copySuccessText="URL copied"
                  textToCopy={copyUrlProps.textToCopy}
                  variant="icon"
                />
                {copyUrlProps.displayText}
              </Box>
            )}
          </>
        }
        trackBy={trackBy}
        items={
          withClientSideSorting || withClientSidePagination ? pageItems : items
        }
        onSelectionChange={(event) => {
          onSelectionChange
            ? onSelectionChange(event)
            : setInternalSelectedEntities(
              selectionType === "single"
                ? event.detail.selectedItems.slice(-1)
                : event.detail.selectedItems,
            );
        }}
        selectedItems={selectedItems ?? internalSelectedItems}
        selectionType={selectionType}
        pagination={
          <TablePagination
            pagesCount={
              withClientSidePagination
                ? pagesCount
                : Math.ceil(
                  items.length / (preferences?.pageSize ?? DEFAULT_PAGE_SIZE),
                )
            }
            currentPageIndex={
              withClientSidePagination ? currentPageIndex : currentPage
            }
            setCurrentPageIndex={setCurrentPage}
            {...(withClientSidePagination ? restPaginationProps : {})}
          />
        }
        preferences={
          contentDisplayOptions ? (
            <TableCollectionPreferences
              preferences={preferences}
              onPreferencesConfirm={onPreferencesConfirm}
              contentDisplayOptions={contentDisplayOptions}
            />
          ) : undefined
        }
        wrapLines={preferences?.wrapLines}
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences?.contentDisplay}
        resizableColumns={true}
        onColumnWidthsChange={onColumnWidthsChange}
        empty={
          <Box textAlign="center" color="inherit">
            {empty}
          </Box>
        }
        sortingDescending={sortingDescending}
        sortingDisabled={false}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item: any) => {
            const isItemSelected = selectedItems.filter(
              (i: any) => i.name === item.name,
            ).length;
            return `${item.name} is ${isItemSelected ? "" : "not"} selected`;
          },
        }}
        {...props}
        {...(withClientSideSorting ? collectionProps : {})}
      />
    </div>
  );
}

export default ServerClientHybridCollectionTable;
