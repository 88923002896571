import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useState,
} from "react";
import Button, { ButtonProps } from "@cloudscape-design/components/button";
import Popover from "@cloudscape-design/components/popover";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { useFocusButton } from "../../../hooks";
import GenAiOutputActionControlButton, {
  GenAiOutputActionControlButtonProps,
} from "./GenAiOutputActionControlButton";
import { GenAiOutputElementIdentifier } from "./genAiTypes";
import styles from "./GenAiOutputActionControl.module.scss";

interface GenAiOutputUserActionControlProps
  extends GenAiOutputActionControlButtonProps,
    GenAiOutputElementIdentifier {
  header?: string;
  buttonText?: string;
  genAiOutput?: ReactNode;
  onAcceptClick?: () => void;
  onDeclineClick?: () => void;
  disabled?: boolean;
}

function GenAiOutputUserActionControl(
  {
    buttonText = "View generative AI output",
    header = "Generative AI output",
    genAiOutput,
    onAcceptClick,
    onDeclineClick,
    identifier,
    disabled,
  }: GenAiOutputUserActionControlProps,
  ref: ForwardedRef<ButtonProps.Ref>,
) {
  const { buttonRef, focusButton } = useFocusButton();
  // Change Popover key to reset Popover open state
  const [popoverKey, setPopoverKey] = useState(1);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => buttonRef.current?.focus(),
    }),
    [buttonRef],
  );

  return (
    <Popover
      key={`gen-ai-output-user-action-popover-${identifier}-${popoverKey}`}
      dismissButton={true}
      dismissAriaLabel={`Dismiss ${header}`}
      triggerAriaLabel={`Trigger ${buttonText} for ${header}`}
      header={header}
      position="bottom"
      size="large"
      triggerType="custom"
      renderWithPortal={true}
      content={
        <div className={styles.content}>
          <SpaceBetween direction="vertical" size="l">
            <div>{genAiOutput}</div>
            <GenAiOutputActionControlButton
              identifier={identifier}
              onAcceptClick={() => {
                setPopoverKey((key) => key + 1);
                onAcceptClick?.();
              }}
              onDeclineClick={() => {
                setPopoverKey((key) => key + 1);
                focusButton();
                onDeclineClick?.();
              }}
            />
          </SpaceBetween>
        </div>
      }
    >
      <Button
        ref={buttonRef}
        data-testid={`gen-ai-output-action-trigger-button-${identifier}`}
        variant="inline-link"
        iconName="gen-ai"
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </Popover>
  );
}

export default forwardRef(GenAiOutputUserActionControl);
