import Alert from "@cloudscape-design/components/alert";
import React, { useEffect, useState } from "react";
import LiveRegion from "@cloudscape-design/components/live-region";
import Box from "@cloudscape-design/components/box";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import { DocumentFormSubmitAction } from "./documentFormTypes";

interface GenerateDocumentMetadataAlertProps {
  isLoading?: boolean;
  isSuccess?: boolean;
  fileName?: string;
  submitAction: DocumentFormSubmitAction;
}

function GenerateDocumentMetadataAlert({
  isLoading,
  isSuccess,
  fileName,
  submitAction,
}: GenerateDocumentMetadataAlertProps) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (isLoading || isSuccess) {
      setDisplay(true);
    }
  }, [isLoading, isSuccess]);

  const alertContent = isLoading ? (
    <>
      <Box margin={{ bottom: "xs" }}>
        {`Generating document metadata${fileName ? ` from ${fileName}` : ""}.`}
      </Box>
      <LoadingBar variant="gen-ai" />
    </>
  ) : (
    <Box>
      {submitAction === DocumentFormSubmitAction.CREATE ? (
        "The following form has been populated with suggested metadata. Review the suggestions and complete the form."
      ) : (
        <span>
          Document metadata has been generated. Select{" "}
          <i>View suggested metadata</i> to accept and retain the suggestions.
        </span>
      )}
    </Box>
  );

  return display && (isLoading || isSuccess) ? (
    <Alert
      data-testid="document-form-generate-metadata-alert"
      dismissAriaLabel="Dismiss alert for generate document metadata"
      type={isLoading ? "info" : "success"}
      onDismiss={() => setDisplay?.(false)}
      dismissible={isSuccess}
    >
      {<LiveRegion>{alertContent}</LiveRegion>}
    </Alert>
  ) : (
    <></>
  );
}

export default GenerateDocumentMetadataAlert;
