import React, { FC, useContext, useEffect, useRef, useState } from "react";
import SplitPanel from "@cloudscape-design/components/split-panel";
import { CollectionPreferencesProps } from "@cloudscape-design/components/collection-preferences";
import ButtonDropdown, {
  ButtonDropdownProps,
} from "@cloudscape-design/components/button-dropdown";
import Button from "@cloudscape-design/components/button";
import { Location, useLocation, useParams } from "react-router-dom";
import {
  useDocuments,
  useSplitPanel,
  useLocalStoragePreferences,
  useProjectByTitle,
  useDocumentMetadataOptions,
} from "../../hooks";
import CustomAppLayout from "../../components/layout/CustomAppLayout";
import { Document, RouterState, UserRole } from "../../client/interfaces";
import TableHeader from "../../components/header/TableHeader";
import { RouteParams } from "../../data/constants/common";
import ServerClientHybridCollectionTable from "../../components/table/ServerClientHybridCollectionTable";
import { MessageContext } from "../../context/MessagingContext";
import {
  GET_DOCUMENT_METADATA_OPTION_ERROR,
  GET_DOCUMENTS_ERROR,
  GET_PROJECT_ERROR,
} from "../../data/constants/errorMessage";
import { AuthContext } from "../../context/AuthContext";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../common/documentConstant";
import { useDocumentDownload } from "../common/documentDownload";
import { useExportDocumentMetadata } from "../common/documentMetadataExport";
import { DOCUMENT_TABLE_COLUMN_DEFINITIONS } from "../common/documentTableColumnDefinition";
import { DOCUMENT_TABLE_FILTERING_PROPERTIES } from "../common/documentTableFilterProperty";
import { useTableColumnWidth } from "../common/tableColumnWidth";
import { useDashboardSplitPanelElement } from "../panel/dashboardSplitPanelElement";
import { useOpenedDocumentSearchParams } from "../common/documentOpenedDocumentSearchParams";
import { useDocumentDelete } from "../common/documentDelete";
import { CopyLink } from "../common/shareLinkCopy";
import { DocumentDashboardAction } from "./documentDashboard/documentDashboardTypes";
import { getDocumentDashboardContextMenuItems } from "./documentDashboard/documentDashboardContextMenu";
import {
  DOCUMENT_TABLE_PREFERENCES,
  DOCUMENT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS,
  TABLE_FILTERING_FUNCTION,
  TABLE_FILTERING_OPTIONS_FROM_DOCUMENTS,
} from "./documentDashboard/documentDashboardTableConfig";

const DOCUMENT_TABLE_PREFERENCES_KEY = "documentTablePreferences";
const DOCUMENT_TABLE_COLUMN_WIDTHS_KEY = "documentTableColumnWidths";
const DOCUMENT_TABLE_SPLIT_PANEL_SIZE_KEY = "documentTableSplitPanelSize";

const DocumentDashboard: FC = () => {
  const { preferences, setPreferences } =
    useLocalStoragePreferences<CollectionPreferencesProps.Preferences>(
      DOCUMENT_TABLE_PREFERENCES_KEY,
    );
  const { widths, onColumnWidthsChange, tableKeyForWidths } =
    useTableColumnWidth(DOCUMENT_TABLE_COLUMN_WIDTHS_KEY);

  const { addErrorMessage, addMessage } = useContext(MessageContext);
  const { isEditor } = useContext(AuthContext);

  const { projectTitle } = useParams<RouteParams>();
  const {
    openedDocumentTitle,
    setOpenedDocumentTitle,
    removeOpenedDocumentTitle,
  } = useOpenedDocumentSearchParams();

  // `state` contains project metadata if the user navigate from the project page. Otherwise, `state` is undefined.
  const { state } = useLocation() as Location<RouterState>;
  const { data: projectByTitle, error: getProjectError } = useProjectByTitle(
    state?.project ? undefined : projectTitle,
  );
  const project = state?.project ?? projectByTitle;
  const {
    data: documents,
    isLoading,
    mutate: reloadDocuments,
    error: getDocumentsError,
  } = useDocuments(project?.projectId);

  const { data: metadataOptions, error: getDocumentMetadataOptionsError } =
    useDocumentMetadataOptions();
  const marketplaceSequence = metadataOptions?.marketplaces?.map(
    ({ value }) => value,
  );

  // Download document
  const { downloadDocument, isLoading: isDownloading } = useDocumentDownload({
    addErrorMessage,
  });

  // Export document metadata
  const { exportDocumentMetadata } = useExportDocumentMetadata({
    addErrorMessage,
  });

  // Table selection states
  const [tableSelectedItem, setTableSelectedItem] = useState<Document[]>([]);

  // Delete document states
  const { deleteDocument, documentDeleteConfirmationModal } = useDocumentDelete(
    {
      modalDataTestId: "document-dashboard-delete-document-modal",
      addMessage,
      addErrorMessage,
      onDocumentChange: (document) => {
        if (document.documentId === openedDocument?.documentId) {
          closeSplitPanel();
        }
        if (document.documentId === tableSelectedItem[0]?.documentId) {
          setTableSelectedItem([]);
        }
        reloadDocuments();
      },
    },
  );

  // Split panel states
  const divRef = useRef<HTMLDivElement>(null);
  const {
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    setSplitPanelOpen,
    onSplitPanelToggle,
  } = useSplitPanel(DOCUMENT_TABLE_SPLIT_PANEL_SIZE_KEY);
  const {
    splitPanelElement,
    splitPanelHeader,
    closeSplitPanel,
    openedDocument,
    viewDocumentDetail,
    createDocument,
    duplicateDocument,
    editDocument,
  } = useDashboardSplitPanelElement({
    setSplitPanelOpen,
    dashboardDivRef: divRef,
    splitPanelSelector: "[data-document-dashboard-selector=\"split-panel\"]",
    setSearchParamOpenedDocumentTitle: setOpenedDocumentTitle,
    removeSearchParamOpenedDocumentTitle: removeOpenedDocumentTitle,
    onDocumentChange: () => reloadDocuments(),
    handleDownloadDocument: ({ document }) =>
      downloadDocument(document.projectId, document.documentId, true),
    handleDeleteDocument: ({ document }) => deleteDocument(document),
    handleExportDocumentMetadata: ({ project, document }) =>
      exportDocumentMetadata(project, [document]),
  });

  useEffect(() => {
    if (getDocumentMetadataOptionsError) {
      addErrorMessage?.(
        getDocumentMetadataOptionsError,
        GET_DOCUMENT_METADATA_OPTION_ERROR,
      );
    }
    if (getDocumentsError) {
      addErrorMessage?.(getDocumentsError, GET_DOCUMENTS_ERROR);
    }
    if (getProjectError) {
      addErrorMessage?.(getProjectError, GET_PROJECT_ERROR);
    }
  }, [getDocumentMetadataOptionsError, getDocumentsError, getProjectError]);

  useEffect(() => {
    if (
      openedDocument &&
      openedDocument.documentId !== tableSelectedItem[0]?.documentId
    ) {
      setTableSelectedItem([]);
    }
  }, [openedDocument]);

  useEffect(() => {
    if (openedDocumentTitle) {
      const document = documents?.find(
        (document) => document.documentTitle === openedDocumentTitle,
      );
      if (document && document.documentId !== openedDocument?.documentId) {
        handleTitleClick(document);
      }
    } else if (openedDocument) {
      closeSplitPanel();
    }
  }, [openedDocumentTitle, documents, openedDocument]);

  function handleTitleClick(document: Document) {
    viewDocumentDetail({ document, project });
  }

  function handleTableHeaderActionItemClick(
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) {
    const { id } = event.detail;
    switch (id) {
    case DocumentDashboardAction.EDIT:
      editDocument({ document: tableSelectedItem[0], project });
      break;
    case DocumentDashboardAction.DELETE:
      deleteDocument(tableSelectedItem[0]);
      break;
    case DocumentDashboardAction.DUPLICATE:
      duplicateDocument({ document: tableSelectedItem[0], project });
      break;
    case DocumentDashboardAction.EXPORT:
      project &&
          tableSelectedItem.length > 0 &&
          exportDocumentMetadata(project, tableSelectedItem);
      break;
    case DocumentDashboardAction.SHARE_SELECTED:
      project &&
          tableSelectedItem.length === 1 &&
          CopyLink.copyDocumentDetailLink(project, tableSelectedItem[0]);
      break;
    }
  }

  const headerButtons = [
    <ButtonDropdown
      key="actions"
      data-testid="document-table-header-actions"
      disabled={tableSelectedItem.length === 0}
      disabledReason="No documents selected"
      items={getDocumentDashboardContextMenuItems({
        isEditor,
        numSelectedItems: tableSelectedItem.length,
      })}
      onItemClick={handleTableHeaderActionItemClick}
    >
      Actions
    </ButtonDropdown>,
    <Button
      key="download"
      data-testid="handle-download-document"
      loading={isDownloading}
      onClick={() =>
        project &&
        tableSelectedItem[0] &&
        downloadDocument(
          project.projectId,
          tableSelectedItem[0].documentId,
          true,
        )
      }
      target="_blank"
      disabled={tableSelectedItem.length === 0 || tableSelectedItem.length > 1}
      disabledReason={
        tableSelectedItem.length === 0
          ? "No document selected"
          : "Please select a single document to perform this action"
      }
    >
      Download document
    </Button>,
    <Button
      key="upload"
      data-testid="handle-upload-document"
      data-roles={`${UserRole.ADMIN} ${UserRole.AUTHOR}`}
      onClick={() => createDocument({ project })}
      variant="primary"
    >
      Upload document
    </Button>,
  ];

  return (
    <div data-testid="document-dashboard" ref={divRef}>
      <CustomAppLayout
        projectTitle={project?.projectTitle}
        content={
          <ServerClientHybridCollectionTable
            key={tableKeyForWidths}
            copyUrlProps={
              project && {
                textToCopy: CopyLink.getProjectLink(project),
                displayText: "Copy URL",
              }
            }
            variant="full-page"
            selectionType="multi"
            selectedItems={tableSelectedItem}
            onSelectionChange={({ detail }) =>
              setTableSelectedItem(detail.selectedItems)
            }
            sortingColumn={{
              sortingField:
                DOCUMENT_PROPERTY_KEY[DocumentMetadataName.UPLOAD_DATE],
            }}
            sortingDescending={true}
            withClientSideFiltering={true}
            withClientSidePagination={true}
            withClientSideSorting={true}
            filteringProperties={DOCUMENT_TABLE_FILTERING_PROPERTIES}
            clientFilteringOptions={TABLE_FILTERING_OPTIONS_FROM_DOCUMENTS(
              documents ?? [],
            )}
            clientFilteringFunction={TABLE_FILTERING_FUNCTION}
            filteringPlaceholder="Find documents by entering texts, keywords, property, or value"
            items={documents ?? []}
            loading={isLoading}
            preferences={preferences ?? DOCUMENT_TABLE_PREFERENCES}
            onPreferencesConfirm={(detail) => setPreferences(detail)}
            columnDefinitions={DOCUMENT_TABLE_COLUMN_DEFINITIONS({
              onTitleClick: handleTitleClick,
              selectedDocument: openedDocument,
              widths,
              marketplaceSequence,
            })}
            onColumnWidthsChange={onColumnWidthsChange}
            contentDisplayOptions={
              DOCUMENT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS
            }
            empty="No documents"
            header={
              <TableHeader
                showBackButton={true}
                showRefreshButton={true}
                refreshButtonOnClick={() => reloadDocuments()}
                buttons={headerButtons}
              >
                {project?.projectTitle}
              </TableHeader>
            }
          />
        }
        splitPanel={
          <SplitPanel
            data-document-dashboard-selector="split-panel"
            header={splitPanelHeader}
            closeBehavior="hide"
            hidePreferencesButton={true}
          >
            {splitPanelElement}
          </SplitPanel>
        }
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={{ position: "side" }}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={(e) => {
          onSplitPanelToggle(e);
          closeSplitPanel();
        }}
      />
      {documentDeleteConfirmationModal}
    </div>
  );
};

export default DocumentDashboard;
