import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import Input, { InputProps } from "@cloudscape-design/components/input";
import TokenGroup, {
  TokenGroupProps,
} from "@cloudscape-design/components/token-group";
import { KeyCode } from "@cloudscape-design/components/internal/keycode";
import { useInput } from "../../hooks";
import CustomFormField from "./CustomFormField";
import { GenAiOutputProps, useGenAiOutput } from "./genai";
import styles from "./Input.module.scss";
import { BaseInputProps, BaseInputRefAttributes } from ".";

export interface MultiTextInputProps<K extends string = string>
  extends BaseInputProps<string[], K>,
    GenAiOutputProps<string[]>,
    Pick<InputProps, "inputMode"> {}

function MultiTextInput<K extends string = string>(
  {
    label,
    description,
    placeholder,
    defaultValue,
    fieldId,
    validate,
    onChange,
    required,
    disabled,
    constraintText,
    genAiOutputValue,
    ...props
  }: MultiTextInputProps<K>,
  ref: ForwardedRef<BaseInputRefAttributes>,
): JSX.Element {
  const {
    value: textInputValue,
    errorText: warningText,
    handleInputChange: handleTextInputChange,
    handleBlur: handleTextInputBlur,
    resetInput: resetTextInput,
  } = useInput<string>({
    validate: (value: string) =>
      value.length === 0
        ? { isValid: true }
        : {
          isValid: false,
          errorText: "You have unsaved inputs",
        },
    initialState: "",
  });
  const {
    value: tokens,
    errorText,
    handleInputChange: handleTokensChange,
    handleBlur: handleTokenBlur,
    resetInput: resetTokens,
  } = useInput<string[]>({
    validate,
    initialState: defaultValue,
  });

  const inputRef = useRef<InputProps.Ref>(null);

  const { genAiOutputElement: GenAiOutputElement } = useGenAiOutput({
    validate,
    genAiOutputValue,
    currentInput: tokens,
    setInput: handleChange,
    resetInput: resetTokens,
    identifier: fieldId,
    label,
  });

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        resetTextInput();
        resetTokens();
        onChange?.(fieldId, {
          value: defaultValue,
          isError: !(validate?.(defaultValue).isValid ?? true),
          isModified: false,
        });
      },
      validate: handleTokenBlur,
      focus: () => inputRef.current?.focus(),
    };
  }, [handleTokenBlur, inputRef]);

  function handleChange(value: string[]) {
    const { isValid, isModified } = handleTokensChange(value);
    onChange?.(fieldId, { value, isError: !isValid, isModified });
    resetTextInput();
  }

  const items: TokenGroupProps.Item[] = tokens.map((item) => ({
    label: item,
    dismissLabel: `Remove ${item}`,
    disabled,
  }));

  return (
    <>
      <CustomFormField
        label={label}
        required={required}
        description={description}
        errorText={errorText}
        constraintText={constraintText}
        warningText={warningText}
      >
        <Input
          value={textInputValue}
          ariaRequired={required}
          placeholder={placeholder}
          onChange={({ detail: { value } }) => {
            handleTextInputChange(value);
          }}
          onBlur={() => {
            handleTextInputBlur();
            handleTokenBlur();
          }}
          onKeyDown={({ detail: { keyCode } }) => {
            if (keyCode === KeyCode.enter && textInputValue) {
              handleChange([...tokens, textInputValue]);
            }
          }}
          disabled={disabled}
          name={fieldId}
          ref={inputRef}
          {...props}
        />
      </CustomFormField>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          handleChange([
            ...tokens.slice(0, itemIndex),
            ...tokens.slice(itemIndex + 1),
          ]);
          handleTokenBlur();
        }}
        items={items}
      />
      <div className={styles.genAiOutputPadding}>{GenAiOutputElement}</div>
    </>
  );
}

export default forwardRef(MultiTextInput);

export function TypedMultiTextInput<K extends string>() {
  return forwardRef<BaseInputRefAttributes, MultiTextInputProps<K>>(
    MultiTextInput,
  );
}
