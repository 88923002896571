import React from "react";
import Box from "@cloudscape-design/components/box";
import Icon from "@cloudscape-design/components/icon";

interface GenAiOutputLabelProps {
  children?: React.ReactNode;
}

function GenAiOutputLabel({
  children = "Generated by AI",
}: GenAiOutputLabelProps) {
  return (
    <Box variant="awsui-gen-ai-label">
      <Icon size="small" name="gen-ai" />
      {children}
    </Box>
  );
}

export default GenAiOutputLabel;
