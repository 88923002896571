import {
  ToggleProps,
  NonCancelableCustomEvent,
} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import React from "react";

interface ProjectDashboardSemanticSearchToggleProps {
  isSemanticSearch?: boolean;
  onSemanticSearchToggle?: (checked: boolean) => void;
}

export function ProjectDashboardSemanticSearchToggle({
  isSemanticSearch = false,
  onSemanticSearchToggle,
}: ProjectDashboardSemanticSearchToggleProps) {
  function handleSemanticSearchToggleChange(
    event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>,
  ) {
    onSemanticSearchToggle?.(event.detail.checked);
  }

  return (
    <Toggle
      data-testid="project-dashboard-semantic-search-toggle"
      onChange={handleSemanticSearchToggleChange}
      checked={isSemanticSearch}
    >
      Semantic search - <i>beta</i>
    </Toggle>
  );
}
