import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import {
  FREE_FILTER_TERM_KEY,
  SEMANTIC_SEARCH_FILTER_KEY,
  VALID_OPERATORS_REGEX,
} from "../../../data/constants/common";
import {
  GetProjectsCommandInput,
  SemanticSearchInput,
} from "../../../client/interfaces";
import { toDateComparisonOperator } from "../../../helpers";

export const SEMANTIC_SEARCH_ENABLED_QUERY_KEY = "semantic";
export const SEMANTIC_SEARCH_ENABLED_QUERY_VALUE = "1";

export function isSemanticSearch(searchParams: URLSearchParams): boolean {
  return (
    searchParams.get(SEMANTIC_SEARCH_ENABLED_QUERY_KEY) ===
    SEMANTIC_SEARCH_ENABLED_QUERY_VALUE
  );
}

export function toPropertyFilterQuery(
  urlSearchParams: URLSearchParams,
): PropertyFilterProps.Query {
  const tokens: PropertyFilterProps.Query["tokens"][number][] = [];
  if (isSemanticSearch(urlSearchParams)) {
    urlSearchParams.forEach((value, key) => {
      const matchedValue = value.match(VALID_OPERATORS_REGEX);
      if (matchedValue && key === SEMANTIC_SEARCH_FILTER_KEY) {
        tokens.push({
          operator: matchedValue[1] ?? "=",
          propertyKey: undefined,
          value: matchedValue[2],
        });
      }
    });
  } else {
    urlSearchParams.forEach((value, key) => {
      const matchedValue = value.match(VALID_OPERATORS_REGEX);
      if (
        matchedValue &&
        key !== SEMANTIC_SEARCH_ENABLED_QUERY_KEY &&
        key !== SEMANTIC_SEARCH_FILTER_KEY
      ) {
        tokens.push({
          operator: matchedValue[1] ?? "=",
          propertyKey: key === FREE_FILTER_TERM_KEY ? undefined : key,
          value: matchedValue[2],
        });
      }
    });
  }
  return {
    operation: "and",
    tokens,
  };
}

type GetProjectsFilterOutput =
  | {
      semanticSearch: false;
      filter: GetProjectsCommandInput;
    }
  | {
      semanticSearch: true;
      filter: SemanticSearchInput;
    };

export function toProjectsFilter(
  urlSearchParams: URLSearchParams,
): GetProjectsFilterOutput {
  if (isSemanticSearch(urlSearchParams)) {
    const filter: SemanticSearchInput = { searchFilter: "" };
    urlSearchParams.forEach((value, key) => {
      const matchedValue = value.match(VALID_OPERATORS_REGEX);
      if (matchedValue && key === SEMANTIC_SEARCH_FILTER_KEY) {
        filter[key] = matchedValue[2];
      }
    });
    return { semanticSearch: true, filter };
  } else {
    const filter: GetProjectsCommandInput = {};
    type GetProjectsCommandInputStringKey = keyof Omit<
      GetProjectsCommandInput,
      "documentExpirationDate" | "lastUploadDate"
    >;
    urlSearchParams.forEach((value, key) => {
      // value includes operator prefix
      const matchedValue = value.match(VALID_OPERATORS_REGEX);
      if (
        matchedValue &&
        key !== SEMANTIC_SEARCH_ENABLED_QUERY_KEY &&
        key !== SEMANTIC_SEARCH_FILTER_KEY
      ) {
        const filterValue = matchedValue[2]; // value without operator prefix
        if (key === "documentExpirationDate" || key === "lastUploadDate") {
          // Only `documentExpirationDate` and `lastUploadDate` support DateComparison in API
          const operator = toDateComparisonOperator(matchedValue[1]); // matchedValue[1] is the operator
          if (operator) {
            filter[key] = { date: parseInt(filterValue), operator };
          }
        } else {
          filter[key as GetProjectsCommandInputStringKey] = filterValue;
        }
      }
    });
    return { semanticSearch: false, filter };
  }
}

export function toURLSearchParams(
  query: PropertyFilterProps.Query,
  semanticSearch: boolean,
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  query.tokens.forEach((token) => {
    urlSearchParams.set(
      token.propertyKey ??
        (semanticSearch ? SEMANTIC_SEARCH_FILTER_KEY : FREE_FILTER_TERM_KEY),
      `${token.operator}${token.value}`,
    );
  });
  if (semanticSearch) {
    urlSearchParams.set(
      SEMANTIC_SEARCH_ENABLED_QUERY_KEY,
      SEMANTIC_SEARCH_ENABLED_QUERY_VALUE,
    );
  }
  return urlSearchParams;
}
